import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  CommunicationPage,
  DailySchedulePage,
  ExpiredPage,
  SignInPage,
  TermPage,
  TrainerManagePage,
  TrainerRegisterPage,
  WeeklySchedulePage,
} from "./scenes";
import { clearLocalStorage, getItemWithExpiration } from "./lib/utils";
import PrivateRoutes from "./lib/PrivateRoutes";
import useInterceptors from "./hook/useInterceptors";
import SideBar from "./components/global/SideBar";
import "./App.css";
import ModalPayPending from "./components/Modal/ModalPayPending";
import Loading from "./components/global/Loading";
import ModalAlert from "./components/Modal/ModalAlert";

const App = () => {
  const location = useLocation();
  const [authorization, setAuthorization] = useState(
    getItemWithExpiration("authorization")
  );
  const [userObject, setUserObject] = useState(
    getItemWithExpiration("userObject")
  );
  const [centerObject, setCenterObject] = useState(
    getItemWithExpiration("centerObject")
  );
  const [planObject, setPlanObject] = useState(
    getItemWithExpiration("planObject")
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 사이드바 상태
  const [isVisibleSidebar, setIsVisibleSidebar] = useState<boolean>(false);
  const [isVisiblePayPending, setIsVisiblePayPending] =
    useState<boolean>(false);

  // 공통 alert 창 상태값
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string | null>(null);
  const [alertContent, setAlertContent] = useState<string | null>(null);
  const [alertCallBack, setAlertCallBack] = useState<any>(null);
  const [isAlertTwoBtn, setIsAlertTwoBtn] = useState<boolean>(false);
  const [confirmBtnText, setConfirmBtnText] = useState<string | null>(null);

  // 공통 모달 state 초기화
  const alertStateReset = () => {
    setIsAlert(false);
    setAlertTitle(null);
    setAlertContent(null);
    setAlertCallBack(null);
    setIsAlertTwoBtn(false);
    setConfirmBtnText(null);
  };

  /**
   * 공통 버튼 모달
   * @param title : 제목 텍스트
   * @param content : 본문 텍스트
   * @param callBack : 콜백함수 (선택)
   * @param isTwoBtn : false 1개 true 2개
   */
  const createAlert = (alertState: any) => {
    setAlertTitle(alertState.title);
    setAlertContent(alertState.content);
    setAlertCallBack(() => alertState.callBack);
    setIsAlertTwoBtn(alertState.isTwoBtn);
    setConfirmBtnText(alertState.confirmText);
    setIsAlert(true);
  };

  // axios 전역감지 응답,요청 코드가 401,403일시 토큰만료 로직 수행
  useInterceptors();

  // 사이드바 버튼 클릭이벤트
  const onClickSideBarButton = () => {
    setIsVisibleSidebar(() => !isVisibleSidebar);
  };

  // 토큰의 유무로 사이드바 판단
  useEffect(() => {
    if (!authorization) {
      setIsVisibleSidebar(false);
    }
  }, [authorization]);

  // 로그인, 회원가입 화면 진입시 토큰 초기화
  useEffect(() => {
    if (location) {
      if (
        location.pathname.includes("signIn") ||
        location.pathname.includes("signUp")
      ) {
        clearLocalStorage();
        setAuthorization(getItemWithExpiration("authorization"));
      }
    }
  }, [location]);

  useEffect(() => {
    const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      window.location.href = "https://www.rappomanager.com";
    }
  }, []);

  return (
    <div className="portrait-only" style={{ overflowX: "hidden" }}>
      <SideBar
        isVisibleSidebar={isVisibleSidebar}
        onClickSideBarButton={onClickSideBarButton}
        userObject={userObject}
        centerObject={centerObject}
        planObject={planObject}
      />

      <Routes>
        <Route path="/" element={<Navigate to="/daily" />} />
        <Route
          path="/signIn"
          element={<SignInPage setAuthorization={setAuthorization} />}
        />

        <Route
          element={
            <PrivateRoutes setIsVisiblePayPending={setIsVisiblePayPending} />
          }
        >
          <Route
            path="/daily"
            element={
              <DailySchedulePage
                onClickSideBarButton={onClickSideBarButton}
                userObject={userObject}
                setUserObject={setUserObject}
                centerObject={centerObject}
                setCenterObject={setCenterObject}
                planObject={planObject}
                setPlanObject={setPlanObject}
                setIsLoading={setIsLoading}
              />
            }
          />
          <Route
            path="/weekly"
            element={
              <WeeklySchedulePage
                onClickSideBarButton={onClickSideBarButton}
                setIsLoading={setIsLoading}
              />
            }
          />
          <Route
            path="/manage"
            element={
              <TrainerManagePage onClickSideBarButton={onClickSideBarButton} />
            }
          />
          <Route
            path="/communication"
            element={
              <CommunicationPage
                onClickSideBarButton={onClickSideBarButton}
                createAlert={createAlert}
                centerObject={centerObject}
                setCenterObject={setCenterObject}
              />
            }
          />

          <Route path="/term" element={<TermPage />} />
          <Route path="/register" element={<TrainerRegisterPage />} />
          <Route
            path="/expired"
            element={<ExpiredPage setIsLoading={setIsLoading} />}
          />
        </Route>
      </Routes>

      {isVisiblePayPending && (
        <ModalPayPending
          modalVisible={isVisiblePayPending}
          setModalVisible={setIsVisiblePayPending}
        />
      )}

      {/* 모달 1버튼 : 공통 알럿창 */}
      {isAlert && alertContent && (
        <ModalAlert
          title={alertTitle}
          content={alertContent}
          callBack={alertCallBack}
          alertStateReset={alertStateReset}
          isAlertTwoBtn={isAlertTwoBtn}
          confirmBtnText={confirmBtnText}
        />
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default App;
