// 커먼
import i_sign_background from '../assets/images/common/i_sign_background.png';
import i_rappo_logo_sign from '../assets/images/common/i_rappo_logo_sign.png';
import i_logo from '../assets/images/common/i_logo.png';
import i_right_blue_triangle from '../assets/images/common/i_right_blue_triangle.png';
import i_right_grey_triangle from '../assets/images/common/i_right_grey_triangle.png';
import i_right_pink_triangle from '../assets/images/common/i_right_pink_triangle.png';
import i_back from '../assets/images/common/i_back.png';
import i_apple from '../assets/images/common/i_apple.png';
import i_android from '../assets/images/common/i_android.png';
import i_green_error from '../assets/images/common/i_green_error.png';
import i_red_error from '../assets/images/common/i_red_error.png';
import i_grey_exit from '../assets/images/common/i_grey_exit.png';
import i_drop_down from '../assets/images/common/i_drop_down.png';
import i_drop_up from '../assets/images/common/i_drop_up.png';
import i_left_gray_triangle from '../assets/images/common/i_left_gray_triangle.png';
import i_right_gray_triangle from '../assets/images/common/i_right_gray_triangle.png';
import i_left_primary_two_triangle from '../assets/images/common/i_left_primary_two_triangle.png';
import i_left_gray_two_triangle from '../assets/images/common/i_left_gray_two_triangle.png';
import i_right_primary_two_triangle from '../assets/images/common/i_right_primary_two_triangle.png';
import i_right_gray_two_triangle from '../assets/images/common/i_right_gray_two_triangle.png';
import i_red_alert from '../assets/images/common/i_red_alert.png';
import i_green_alert from '../assets/images/common/i_green_alert.png';
import i_white_back from '../assets/images/common/i_white_back.png';

// 사이드바
import i_hamburger from '../assets/images/sidebar/i_hamburger.png';
import i_exit from '../assets/images/sidebar/i_exit.png';
import i_empty_profile from '../assets/images/sidebar/i_empty_profile.png';
import i_primary_plus from '../assets/images/sidebar/i_primary_plus.png';

// 스케줄
import i_left_primary from '../assets/images/schedule/i_left_primary.png';
import i_right_primary from '../assets/images/schedule/i_right_primary.png';
import i_left_primary_triangle from '../assets/images/schedule/i_left_primary_triangle.png';
import i_right_primary_triangle from '../assets/images/schedule/i_right_primary_triangle.png';

// 달력 (칼렌더)
import i_right_primary_btn from '../assets/images/calendar/i_right_primary_btn.png';
import i_left_primary_btn from '../assets/images/calendar/i_left_primary_btn.png';

// 커먼
export const I_SIGN_BACKGROUND = i_sign_background;
export const I_RAPPO_LOGO_SIGN = i_rappo_logo_sign;
export const I_LOGO = i_logo;
export const I_RIGHT_BLUE_TRIANGLE = i_right_blue_triangle;
export const I_RIGHT_GREY_TRIANGLE = i_right_grey_triangle;
export const I_RIGHT_PINK_TRIANGLE = i_right_pink_triangle;
export const I_BACK = i_back;
export const I_APPLE = i_apple;
export const I_ANDROID = i_android;
export const I_GREEN_ERROR = i_green_error;
export const I_RED_ERROR = i_red_error;
export const I_GREY_EXIT = i_grey_exit;
export const I_DROP_DOWN = i_drop_down;
export const I_DROP_UP = i_drop_up;
export const I_LEFT_GRAY_TRIANGLE = i_left_gray_triangle;
export const I_RIGHT_GRAY_TRIANGLE = i_right_gray_triangle;
export const I_LEFT_PRIMARY_TWO_TRIANGLE = i_left_primary_two_triangle;
export const I_LEFT_GRAY_TWO_TRIANGLE = i_left_gray_two_triangle;
export const I_RIGHT_PRIMARY_TWO_TRIANGLE = i_right_primary_two_triangle;
export const I_RIGHT_GRAY_TWO_TRIANGLE = i_right_gray_two_triangle;
export const I_RED_ALERT = i_red_alert;
export const I_GREEN_ALERT = i_green_alert;
export const I_WHITE_BACK = i_white_back;

// 사이드바
export const I_HAMBURGER = i_hamburger;
export const I_EXIT = i_exit;
export const I_EMPTY_PROFILE = i_empty_profile;
export const I_PRIMARY_PLUS = i_primary_plus;

// 스케줄
export const I_LEFT_PRIMARY = i_left_primary;
export const I_RIGHT_PRIMARY = i_right_primary;
export const I_LEFT_PRIMARY_TRIANGLE = i_left_primary_triangle;
export const I_RIGHT_PRIMARY_TRIANGLE = i_right_primary_triangle;

// 달력 (칼렌더)
export const I_RIGHT_PRIMARY_BTN = i_right_primary_btn;
export const I_LEFT_PRIMARY_BTN = i_left_primary_btn;
