import React, { CSSProperties } from 'react';
import { Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Body1Bold } from '../../lib/font';
import styled from 'styled-components';

type Props = {
    text: string;
    onClick?: any;
    disabled?: boolean;
    style?: CSSProperties;
};

const ButtonWrap = styled.button`
    width: 148px;
    height: 48px;
    border-radius: 48px;
    background-color: rgba(240, 246, 255, 1);
    color: ${(props) => (props.disabled ? 'rgba(165, 171, 181, 1)' : 'rgba(26, 48, 93, 1)')};
    border: none;
    box-shadow: 1px 1px 8px 0px rgba(43, 82, 157, 0.18);
`;

const CustomButton = ({ text, onClick, disabled, style }: Props) => {
    return (
        <ButtonWrap disabled={disabled} style={style} onClick={onClick}>
            <Body1Bold style={{ color: colors.BLUE_900 }}>{text}</Body1Bold>
        </ButtonWrap>
    );
};

export default CustomButton;
