import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { getDailyScheduleAPI } from "../api/schedule";
import {
  calHeight,
  calHeightToPixel,
  getItemWithExpiration,
} from "../lib/utils";
import { Col } from "../lib/styled";
import Header from "../components/global/Header";
import DailyScheduleTop from "../components/DailySchedule/DailyScheduleTop";
import { colors } from "../lib/colors";
import DailyScheduleLayout from "../components/DailySchedule/DailyScheduleLayout";
import DailyScheduleBox from "../components/DailySchedule/DailyScheduleBox";
import Calendar from "../components/Calendar/Calendar";
import DailyScheduleShadow from "../components/DailySchedule/DailyScheduleShadow";

type Props = {
  onClickSideBarButton: () => void;
  centerObject: any;
  setCenterObject: any;
  userObject: any;
  setUserObject: any;
  planObject: any;
  setPlanObject: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const DailySchedulePage = ({
  onClickSideBarButton,
  centerObject,
  setCenterObject,
  userObject,
  setUserObject,
  planObject,
  setPlanObject,
  setIsLoading,
}: Props) => {
  const containerRef: any = useRef(null);
  const scrollbarRef: any = useRef(null);

  const [authorization, setAuthorization] = useState(
    getItemWithExpiration("authorization")
  );

  const [dailyScheduleList, setDailyScheduleList] = useState<any>(null);
  const [dailyScheduleCount, setDailyScheduleCount] = useState<number>(0);
  const [dailyCurrentData, setDailyCurrentData] = useState<any>(null);

  /** 페이징 처리 */
  const pageSize = 4;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  let endPage: number = 0;
  endPage = dailyScheduleList && Math.ceil(dailyScheduleList.length / pageSize);

  /** 캘린더 state */
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [isCalendar, setIsCalendar] = useState<boolean>(false);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(dailyScheduleList.length / pageSize)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onClickAddDate = () => {
    const newDate = new Date(selectedDay);
    newDate.setDate(selectedDay.getDate() + 1);
    setSelectedDay(newDate);
  };

  const onClickSubDate = () => {
    const newDate = new Date(selectedDay);
    newDate.setDate(selectedDay.getDate() - 1);
    setSelectedDay(newDate);
  };

  // 달력의 바깥부분 클릭시 달력모달 사라지게
  const onClickOutSide = (event: any) => {
    if (containerRef.current && !event.target.classList.contains("exclude")) {
      if (isCalendar) {
        setIsCalendar(false);
      } else {
        return;
      }
    }
  };

  // 일간 강사 스케줄 조회 API : (GET)
  const getDailySchedule = useQuery(
    ["getDailyScheduleAPI", selectedDay, authorization],
    async () =>
      await getDailyScheduleAPI(
        centerObject.centerId,
        dayjs(selectedDay).format("YYYY-MM-DD"),
        authorization
      ),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          const response: any = res.data;
          setDailyScheduleList(response.dailySchedules);
          setDailyScheduleCount(response.dailySchedulesCount);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      enabled: !!authorization && !!(centerObject && centerObject?.centerId),
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (selectedDay) {
      setCurrentPage(1);
    }
  }, [selectedDay]);

  // 강사 페이징 처리
  useEffect(() => {
    if (dailyScheduleList) {
      let filterData = dailyScheduleList.slice(startIndex, endIndex);
      setDailyCurrentData(filterData);
    }
  }, [dailyScheduleList, currentPage]);

  useEffect(() => {
    if (scrollbarRef.current) {
      window.scrollTo(0, 84.9 * parseInt(dayjs().format("H")));
    }
  }, [scrollbarRef]);

  useEffect(() => {
    setUserObject(getItemWithExpiration("userObject"));
    setCenterObject(getItemWithExpiration("centerObject"));
    setPlanObject(getItemWithExpiration("planObject"));
  }, []);

  // loading flag
  useEffect(() => {
    if (getDailySchedule) {
      if (getDailySchedule.isLoading) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  }, [getDailySchedule]);

  return (
    <Col
      style={{
        width: "100vw",
        minHeight: "100dvh",
        backgroundColor: colors.WHITE_300,
      }}
      onClick={onClickOutSide}
      ref={containerRef}
    >
      <Header
        onClick={onClickSideBarButton}
        headerText={"일간 강사스케줄"}
        isFixed={true}
      />
      <DailyScheduleTop
        dailyCurrentData={dailyCurrentData}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        endPage={endPage}
        currentPage={currentPage}
        setIsCalendar={setIsCalendar}
        selectedDay={selectedDay}
        onClickAddDate={onClickAddDate}
        onClickSubDate={onClickSubDate}
        dailyScheduleCount={dailyScheduleCount}
      />

      <Col
        ref={scrollbarRef}
        style={{ width: "100vw", marginTop: calHeightToPixel(262) }}
      >
        {/* 선그리는 컴포넌트 */}
        <DailyScheduleLayout />
        <DailyScheduleBox dailyCurrentData={dailyCurrentData} />
        <DailyScheduleShadow selectedDay={selectedDay} />
      </Col>

      {isCalendar && (
        <Calendar
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          setIsCalendar={setIsCalendar}
          isPrevMonth={true}
          isNextMonth={true}
        />
      )}
    </Col>
  );
};

export default DailySchedulePage;
