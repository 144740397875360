import axios from 'axios';
import React from 'react';
import { clearLocalStorage } from '../lib/utils';
import { useNavigate } from 'react-router-dom';

const useInterceptors = () => {
    const navigate = useNavigate();
    // api 요청 전역감지
    axios.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            if ((error.response && error.response.status === 401) || error.response.status === 403) {
                alert('세션이 만료되었습니다');
                clearLocalStorage();
                navigate('/signIn');
            }

            return Promise.reject(error);
        }
    );

    // api 응답 전역감지
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if ((error.response && error.response.status === 401) || error.response.status === 403) {
                alert('세션이 만료되었습니다');
                clearLocalStorage();
                navigate('/signIn');
            }

            return Promise.reject(error);
        }
    );
};

export default useInterceptors;
