import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Col } from '../lib/styled';
import { colors } from '../lib/colors';
import Header from '../components/global/Header';
import WeeklyScheduleTop from '../components/WeeklySchedule/WeeklyScheduleTop';
import { calHeight, calHeightToPixel, getItemWithExpiration } from '../lib/utils';
import { useQuery } from '@tanstack/react-query';
import { getWeeklyScheduleAPI } from '../api/schedule';
import dayjs from 'dayjs';
import WeeklyCalendar from '../components/WeeklySchedule/WeeklyCalendar';
import ScheduleLayout from '../components/WeeklySchedule/WekklyScheduleBody/ScheduleLayout';

type Props = {
    onClickSideBarButton: () => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const WeeklySchedulePage = ({ onClickSideBarButton, setIsLoading }: Props) => {
    const containerRef: any = useRef(null);
    const scrollbarRef: any = useRef(null);
    const HEIGHT_NUMBER = window.innerHeight < 700 ? 225 : window.innerHeight < 830 ? 200 : 199;

    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [centerObject, setCenterObject] = useState(getItemWithExpiration('centerObject'));

    const [gridData, setGridData] = useState<any>(null); // 그리드 그리는 데이터
    const [scheduleList, setScheduleList] = useState<any>(null);
    const [entireScheduleList, setEntireScheduleList] = useState<any>(null);
    const [lengthArr, setLengthArr] = useState<any>(); // 각시간마다의 길이만 담고있는 배열
    const [scheduleCount, setScheduleCount] = useState<number>(0);

    const [dayIndex, setDayIndex] = useState<number>(0);

    const [isPrevWeek, setIsPrevWeek] = useState<boolean>(false);
    const [isAfterWeek, setIsAfterWeek] = useState<boolean>(false);
    const [isThisWeek, setIsThisWeek] = useState<boolean>(false);

    /** 캘린더 state */
    const [selectedDay, setSelectedDay] = useState<Date>(new Date());
    const [isCalendar, setIsCalendar] = useState<boolean>(false);

    const [scrollCount, setScrollCount] = useState<number>(0);

    const onClickAddDate = () => {
        const newDate = new Date(selectedDay);
        newDate.setDate(selectedDay.getDate() + 7);
        setSelectedDay(newDate);
    };

    const onClickSubDate = () => {
        const newDate = new Date(selectedDay);
        newDate.setDate(selectedDay.getDate() - 7);
        setSelectedDay(newDate);
    };

    const onClickNextDate = () => {
        if (dayIndex >= 4) {
            return;
        } else {
            setDayIndex(dayIndex + 1);
        }
    };
    const onClickPrevDate = () => {
        if (dayIndex <= 0) {
            return;
        } else {
            setDayIndex(dayIndex - 1);
        }
    };

    // 달력의 바깥부분 클릭시 달력모달 사라지게
    const onClickOutSide = (event: any) => {
        if (containerRef.current && !event.target.classList.contains('exclude')) {
            if (isCalendar) {
                setIsCalendar(false);
            } else {
                return;
            }
        }
    };

    useEffect(() => {
        let sliceArr: any;

        if (entireScheduleList) {
            if (dayIndex >= 4) {
                sliceArr = entireScheduleList.slice(4, 7);
            } else {
                sliceArr = entireScheduleList.slice(dayIndex, dayIndex + 3);
            }

            if (sliceArr) {
                let gridData: any = Array.from({ length: 24 }, (_, rowIndex) =>
                    Array.from({ length: 4 }, (_, colIndex) => ({
                        id: `${rowIndex}-${colIndex}`,
                        content: colIndex === 0 ? `${rowIndex}시` : `${rowIndex}-${colIndex}`,
                        schedules: [],
                    }))
                );
                for (let i = 0; i < sliceArr.length; i++) {
                    for (let j = 0; j < sliceArr[i].schedules.length; j++) {
                        gridData[j][i + 1].schedules = sliceArr[i].schedules[j];
                        gridData[j][i + 1].dateFormat = sliceArr[i].dateFormat;
                    }
                }

                // 시간 배열생성
                let arr = Array.from({ length: 24 }, () => new Array(3).fill(null));
                for (let i = 0; i < sliceArr.length; i++) {
                    for (let j = 0; j < 24; j++) {
                        arr[j][i] = sliceArr[i].schedules[j].length;
                    }
                }

                setScheduleList(sliceArr);
                setGridData(gridData);
                setLengthArr(arr);
            }
        }
    }, [dayIndex, entireScheduleList]);

    useEffect(() => {
        if (selectedDay) {
            dayjs(dayjs(selectedDay).endOf('week')).isBefore(dayjs()) ? setIsPrevWeek(true) : setIsPrevWeek(false);

            dayjs(dayjs(selectedDay).startOf('week')).isAfter(dayjs()) ? setIsAfterWeek(true) : setIsAfterWeek(false);

            dayjs().startOf('week').format('YYYY-MM-DD') === dayjs(selectedDay).startOf('week').format('YYYY-MM-DD')
                ? setIsThisWeek(true)
                : setIsThisWeek(false);
        }
    }, [selectedDay]);

    // 주간 강사 스케줄 조회 API : (GET)
    const getWeeklySchedule = useQuery(
        ['getDailyScheduleAPI', selectedDay],
        async () =>
            await getWeeklyScheduleAPI(
                centerObject.centerId,
                dayjs(selectedDay).startOf('week').format('YYYY-MM-DD'),
                authorization
            ),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const apiData = res.data.weeklySchedules;
                    setScheduleCount(res.data.weeklySchedulesCount);
                    let sliceArr: any;
                    setScrollCount((prev) => prev + 1);
                    if (apiData) {
                        // 전체 스케줄 데이터 삽입
                        setEntireScheduleList(apiData);

                        // 오늘에 해당하는 인덱스를 찾아 3개로 끊는로직
                        let todayIndex =
                            dayjs().startOf('week').format('YYYY-MM-DD') ===
                            dayjs(selectedDay).startOf('week').format('YYYY-MM-DD')
                                ? apiData.findIndex((item: any) => item.daysOfWeek === dayjs().format('ddd'))
                                : 0;

                        if (todayIndex !== -1) {
                            setDayIndex(todayIndex);
                            if (todayIndex >= 4) {
                                sliceArr = apiData.slice(4, 7);
                            } else {
                                sliceArr = apiData.slice(todayIndex, todayIndex + 3);
                            }
                        }

                        // console.log(sliceArr);
                        if (sliceArr) {
                            let gridData: any = Array.from({ length: 24 }, (_, rowIndex) =>
                                Array.from({ length: 4 }, (_, colIndex) => ({
                                    id: `${rowIndex}-${colIndex}`,
                                    content: colIndex === 0 ? `${rowIndex}시` : `${rowIndex}-${colIndex}`,
                                    schedules: [],
                                    dateFormat: '',
                                }))
                            );
                            for (let i = 0; i < sliceArr.length; i++) {
                                for (let j = 0; j < sliceArr[i].schedules.length; j++) {
                                    gridData[j][i + 1].schedules = sliceArr[i].schedules[j];
                                    gridData[j][i + 1].dateFormat = sliceArr[i].dateFormat;
                                }
                            }

                            // 시간 배열생성
                            let arr = Array.from({ length: 24 }, () => new Array(3).fill(null));
                            for (let i = 0; i < sliceArr.length; i++) {
                                for (let j = 0; j < 24; j++) {
                                    arr[j][i] = sliceArr[i].schedules[j].length;
                                }
                            }

                            setScheduleList(sliceArr);
                            setGridData(gridData);
                            setLengthArr(arr);
                        }
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    useEffect(() => {
        if (scrollbarRef.current) {
            if (gridData) {
                if (scrollCount === 1) {
                    window.scrollTo(
                        0,
                        calHeightToPixel((window.innerHeight < 745 ? 85.3 : 84.9) * parseInt(dayjs().format('H'))) - 200
                    );
                }
            }
        }
    }, [gridData]);

    // loading flag
    useEffect(() => {
        if (getWeeklySchedule) {
            if (getWeeklySchedule.isLoading) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
        }
    }, [getWeeklySchedule]);

    return (
        <Col
            style={{ width: '100vw', minHeight: '100dvh', backgroundColor: colors.WHITE_300, overflowX: 'hidden' }}
            onClick={onClickOutSide}
            ref={containerRef}
        >
            <Header onClick={onClickSideBarButton} headerText={'주간 강사스케줄'} isFixed={true} />
            <WeeklyScheduleTop
                setIsCalendar={setIsCalendar}
                selectedDay={selectedDay}
                onClickAddDate={onClickAddDate}
                onClickSubDate={onClickSubDate}
                scheduleCount={scheduleCount}
                onClickPrevDate={onClickPrevDate}
                onClickNextDate={onClickNextDate}
                dayIndex={dayIndex}
                scheduleList={scheduleList}
            />

            <Col style={{ marginTop: calHeight(HEIGHT_NUMBER) }} ref={scrollbarRef}>
                <ScheduleLayout
                    isPrevWeek={isPrevWeek}
                    isAfterWeek={isAfterWeek}
                    gridData={gridData}
                    lengthArr={lengthArr}
                    isThisWeek={isThisWeek}
                />
            </Col>

            {isCalendar && (
                <WeeklyCalendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                />
            )}
        </Col>
    );
};

export default WeeklySchedulePage;
