import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const MINUTE = 1000 * 60;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1 * MINUTE,
            cacheTime: 5 * MINUTE,
            refetchOnWindowFocus: false,
            enabled: false,
            retry: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);
