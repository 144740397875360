import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { colors } from '../../lib/colors';
import { Body2Bold, Body3Bold } from '../../lib/font';

import styled from 'styled-components';
import { Col, Row } from '../../lib/styled';
import { I_LEFT_PRIMARY_BTN, I_RIGHT_PRIMARY_BTN } from '../../types/images';
import { calHeight, calWidth } from '../../lib/utils';

type CalendarProps = {
    selectedDay: any;
    setSelectedDay: any;
    isPrevMonth: any;
    isNextMonth: any;
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
};
const HoverAbleTodayBeforeCell: any = styled.td`
    background-color: ${({ clicked }: any) =>
        clicked === 'true' ? '#3D73DD' : '#FDFEFF'}; /* 클릭 여부에 따른 배경색 설정 */
    border-radius: 25px;
    color: ${({ clicked }: any) => (clicked === 'true' ? '#FDFEFF' : '#1A305D')};
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* 부드러운 전환 효과 */
`;

const HoverAbleTodayAfterCell: any = styled.td`
    background-color: ${({ clicked, today }: any) =>
        clicked === 'true' ? '#3D73DD' : today === 'true' ? '#E9F1FF' : '#FDFEFF'}; /* 클릭 여부에 따른 배경색 설정 */
    border-radius: 25px;
    color: ${({ clicked }: any) => (clicked === 'true' ? '#FDFEFF' : '#1A305D')};
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* 부드러운 전환 효과 */
`;

const Calendar = ({ selectedDay, setSelectedDay, isPrevMonth, isNextMonth, setIsCalendar }: CalendarProps) => {
    const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isSameDay = (toDay: Date, compareDay?: Date | null) => {
        if (
            toDay.getFullYear() === compareDay?.getFullYear() &&
            toDay.getMonth() === compareDay?.getMonth() &&
            toDay.getDate() === compareDay?.getDate()
        ) {
            return true;
        }
        return false;
    };

    const onClickDay = (day: Date) => {
        if (isSameDay(day, selectedDay)) {
            setSelectedDay(day);
        } else {
            setSelectedDay(day);
        }
        setIsCalendar(false);
    };

    const prevCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate()));
    };

    const nextCalendar = (event: React.MouseEvent) => {
        event.stopPropagation();
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, currentMonth.getDate()));
    };

    const buildCalendarDays = () => {
        const curMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
        const curMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const prevMonthEndDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
        const nextMonthStartDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
        const days: Date[] = Array.from({ length: curMonthStartDate }, (_, i) => {
            return new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, prevMonthEndDate.getDate() - i);
        }).reverse();

        days.push(
            ...Array.from(
                { length: curMonthEndDate.getDate() },
                (_, i) => new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i + 1)
            )
        );

        const remainingDays = 7 - (days.length % 7);
        if (remainingDays < 7) {
            days.push(
                ...Array.from(
                    { length: remainingDays },
                    (_, i) => new Date(nextMonthStartDate.getFullYear(), nextMonthStartDate.getMonth(), i + 1)
                )
            );
        }
        return days;
    };

    const buildCalendarTag = (calendarDays: Date[]) => {
        return calendarDays.map((day: Date, i: number) => {
            if (day.getMonth() < currentMonth.getMonth()) {
                return (
                    <td style={{ color: colors.WHITE_700 }} key={i} className="prevMonthDay">
                        {isPrevMonth ? day.getDate() : ''}
                    </td>
                );
            }
            if (day.getMonth() > currentMonth.getMonth()) {
                return (
                    <td style={{ color: colors.WHITE_700 }} key={i} className="nextMonthDay">
                        {isNextMonth ? day.getDate() : ''}
                    </td>
                );
            }
            if (day < today) {
                return (
                    <HoverAbleTodayBeforeCell
                        key={i}
                        clicked={isSameDay(day, selectedDay) ? 'true' : 'false'}
                        onClick={() => onClickDay(day)}
                    >
                        {day.getDate()}
                    </HoverAbleTodayBeforeCell>
                );
            }

            return (
                <HoverAbleTodayAfterCell
                    key={i}
                    clicked={isSameDay(day, selectedDay) ? 'true' : 'false'}
                    today={today.getDate() === day.getDate() && today.getMonth() === day.getMonth() ? 'true' : 'false'}
                    onClick={() => onClickDay(day)}
                >
                    {day.getDate()}
                </HoverAbleTodayAfterCell>
            );
        });
    };

    const divideWeek = (calendarTags: JSX.Element[]) => {
        return calendarTags.reduce((acc: JSX.Element[][], day: JSX.Element, i: number) => {
            if (i % 7 === 0) acc.push([day]);
            else acc[acc.length - 1].push(day);
            return acc;
        }, []);
    };

    useEffect(() => {
        if (selectedDay) {
            setCurrentMonth(new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate()));
        }
    }, [selectedDay]);

    const calendarDays = buildCalendarDays();
    const calendarTags = buildCalendarTag(calendarDays);
    const calendarRows = divideWeek(calendarTags);

    return (
        <Col
            className="exclude"
            style={{
                width: 328,
                height: 320,
                borderRadius: 24,
                backgroundColor: colors.WHITE_50,
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                position: 'fixed',

                alignItems: 'center',
                justifyContent: 'center',
                top: calHeight(215),
                left: '50%',
                transform: 'translate(-50%)',

                zIndex: 10,
            }}
        >
            <Row style={{ width: 328, alignItems: 'center' }}>
                <div onClick={prevCalendar} style={{ width: 24, height: 24, marginLeft: 111 }}>
                    <img style={{ width: 24, height: 24 }} src={I_LEFT_PRIMARY_BTN} />
                </div>
                <Body2Bold
                    style={{
                        width: 40,
                        textAlign: 'center',
                        color: colors.BLUE_700,
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                >
                    {currentMonth.getMonth() + 1}월
                </Body2Bold>

                <div onClick={nextCalendar} style={{ width: 24, height: 24 }}>
                    <img style={{ width: 24, height: 24 }} src={I_RIGHT_PRIMARY_BTN} />
                </div>

                <Row
                    style={{
                        width: 56,
                        height: 28,
                        borderRadius: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: colors.WHITE_300,
                        marginLeft: 33,
                        color: colors.PRIMARY_BLUE,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                    }}
                    onClick={() => {
                        setSelectedDay(today);
                        setIsCalendar(false);
                    }}
                >
                    <Body3Bold>오늘</Body3Bold>
                </Row>
            </Row>
            <table style={{ width: 280, marginTop: 20 }}>
                <thead>
                    <tr>
                        {daysOfWeek.map((day, i) => (
                            <th key={i}>
                                <Body2Bold style={{ color: colors.BLUE_700 }}>{day}</Body2Bold>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {calendarRows.map((row: JSX.Element[], i: number) => (
                        <tr
                            style={{
                                fontSize: 12,
                                fontWeight: 400,
                                // height: 42,
                                color: colors.BLUE_900,
                                textAlign: 'center',
                            }}
                            key={i}
                        >
                            {row}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Col>
    );
};

export default Calendar;
