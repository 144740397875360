import React, { Fragment } from 'react';
import { Col, Row } from '../../../lib/styled';
import { colors } from '../../../lib/colors';
import { calHeight, calWidth } from '../../../lib/utils';
import { Body3Bold, CaptionBold, CaptionLight } from '../../../lib/font';
import dayjs from 'dayjs';

const DaysList = ({ scheduleList }: any) => {
    return (
        <Row
            style={{
                zIndex: 1,
                backgroundColor: colors.WHITE_100,
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                alignItems: 'center',
            }}
        >
            <Row
                style={{
                    minWidth: calWidth(43.5),
                    height: 46,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            ></Row>
            {scheduleList &&
                scheduleList.map((item: any, index: number) => (
                    <Fragment key={'dayList' + index}>
                        <Col
                            key={index}
                            style={{
                                minWidth: calWidth(116),
                                height: calHeight(46),
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 10,
                            }}
                        >
                            <Row
                                style={{
                                    width: calWidth(22),
                                    height: calWidth(22),
                                    borderRadius: 21,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor:
                                        dayjs().format('ddd') === item.daysOfWeek && dayjs().format('D일') === item.day
                                            ? colors.PRIMARY_BLUE
                                            : colors.WHITE_50,
                                }}
                            >
                                <Body3Bold
                                    style={{
                                        color:
                                            dayjs().format('ddd') === item.daysOfWeek &&
                                            dayjs().format('D일') === item.day
                                                ? colors.WHITE_50
                                                : colors.BLUE_700,
                                    }}
                                >
                                    {item.daysOfWeek}
                                </Body3Bold>
                            </Row>

                            {dayjs().format('ddd') === item.daysOfWeek && dayjs().format('D일') === item.day ? (
                                <CaptionBold style={{ color: colors.WHITE_900 }}>{item.day}</CaptionBold>
                            ) : (
                                <CaptionLight style={{ color: colors.WHITE_800 }}>{item.day}</CaptionLight>
                            )}
                        </Col>
                        {index === 6 ? (
                            <></>
                        ) : (
                            <div
                                style={{
                                    width: calWidth(1),
                                    height: calHeight(12),
                                    backgroundColor: colors.BLUE_200,
                                }}
                            />
                        )}
                    </Fragment>
                ))}
        </Row>
    );
};

export default DaysList;
