import React from 'react';
import { Col, Row } from '../../lib/styled';
import { Body1Bold, Body2Regular, Body3Bold } from '../../lib/font';
import { colors } from '../../lib/colors';
import { calHeight, calWidth } from '../../lib/utils';
import { I_ANDROID, I_APPLE } from '../../types/images';

const TrainerRegisterStep1 = ({ onClickClipBoard }: any) => {
    return (
        <Col>
            <Col style={{ marginLeft: calWidth(16), marginTop: calHeight(32) }}>
                <Body2Regular style={{ color: colors.WHITE_800 }}>
                    트레이너의 휴대폰에 ‘라포'를 다운로드를 하여
                </Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>‘트레이너 회원가입’을 진행해주세요</Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>
                    이미 다운로드가 되어있다면 ‘다음'을 눌러주세요
                </Body2Regular>
            </Col>

            <Row style={{ alignItems: 'center', marginLeft: calWidth(24), marginTop: calHeight(50) }}>
                <img style={{ width: calWidth(18), height: calWidth(18), marginRight: 3 }} src={I_APPLE} />
                <Body3Bold style={{ color: colors.WHITE_900 }}>IOS</Body3Bold>
            </Row>

            <Row
                style={{
                    width: calWidth(200),
                    height: calHeight(56),
                    borderRadius: 48,
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #3D73DD',
                    marginLeft: calWidth(16),
                    marginTop: calHeight(8),
                }}
                onClick={() => {
                    onClickClipBoard('ios');
                }}
            >
                <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>앱 스토어 링크 공유</Body1Bold>
            </Row>

            <Row style={{ alignItems: 'center', marginLeft: calWidth(24), marginTop: calHeight(48) }}>
                <img style={{ width: calWidth(18), height: calWidth(18), marginRight: 3 }} src={I_ANDROID} />
                <Body3Bold style={{ color: colors.WHITE_900 }}>Android</Body3Bold>
            </Row>

            <Row
                style={{
                    width: calWidth(200),
                    height: calHeight(56),
                    borderRadius: 48,
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #3D73DD',
                    marginLeft: calWidth(16),
                    marginTop: calHeight(8),
                }}
                onClick={() => {
                    onClickClipBoard('android');
                }}
            >
                <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>구글 스토어 링크 공유</Body1Bold>
            </Row>
        </Col>
    );
};

export default TrainerRegisterStep1;
