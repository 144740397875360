import React from 'react';
import { Col, Row } from '../../lib/styled';
import { Body1Bold, Body3Bold, Body3Light, Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { calHeight, calWidth } from '../../lib/utils';
import { I_EMPTY_PROFILE, I_RIGHT_BLUE_TRIANGLE, I_RIGHT_PINK_TRIANGLE } from '../../types/images';

const TrainerManageTop = ({ currentTrainerList }: any) => {
    return (
        <Col style={{ marginTop: calHeight(16) }}>
            <Body1Bold style={{ color: colors.BLUE_700 }}>등록된 트레이너</Body1Bold>

            <Body3Light style={{ color: colors.WHITE_800, marginTop: 8, marginRight: calWidth(16) }}>
                우리 센터에서 라포 app으로 연동되어있는 트레이너의 목록입니다. 해당 트레이너의 자세한 정보는 PC로
                확인해주세요.
            </Body3Light>
            <Col style={{ marginTop: calHeight(24) }}>
                {currentTrainerList &&
                    currentTrainerList.map((item: any, index: number) => {
                        return (
                            <Col
                                key={'currentList' + index}
                                style={{
                                    width: calWidth(358),
                                    height: calHeight(138),
                                    backgroundColor: colors.WHITE_50,
                                    borderRadius: 8,
                                    marginBottom: calHeight(8),
                                    boxShadow: '1px 1px 8px 0px #2B529D2E',
                                }}
                            >
                                <Row style={{ alignItems: 'center', marginTop: calHeight(16) }}>
                                    <img
                                        src={item.isConnected ? I_RIGHT_BLUE_TRIANGLE : I_RIGHT_PINK_TRIANGLE}
                                        style={{ width: 10, height: calHeight(46), marginRight: 14 }}
                                    />
                                    <img src={I_EMPTY_PROFILE} style={{ width: calWidth(46), height: calWidth(46) }} />
                                    <Col style={{ marginLeft: 8, justifyContent: 'center' }}>
                                        <Body1Bold style={{ width: calWidth(88), height: calHeight(24) }}>
                                            {item.coachName}
                                        </Body1Bold>
                                        <Body3Regular style={{ width: calWidth(36), height: calHeight(18) }}>
                                            {item.coachInviteCode}
                                        </Body3Regular>
                                    </Col>
                                    {!item.isConnected && (
                                        <Row
                                            style={{
                                                width: calWidth(120),
                                                height: calHeight(42),
                                                borderRadius: 48,
                                                backgroundColor: colors.WHITE_400,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginLeft: calWidth(50),
                                            }}
                                        >
                                            <Body3Bold style={{ color: colors.WHITE_700 }}>등록 대기중</Body3Bold>
                                        </Row>
                                    )}
                                </Row>

                                <Row style={{ marginLeft: calWidth(16), marginTop: calHeight(16) }}>
                                    <Body3Regular
                                        style={{ width: calWidth(100), height: calHeight(18), color: colors.WHITE_700 }}
                                    >
                                        총 회원 수 :{item.coachTotalMemberCount}명
                                    </Body3Regular>
                                    <Body3Regular
                                        style={{ width: calWidth(220), height: calHeight(18), color: colors.WHITE_700 }}
                                    >
                                        이메일 : {item.coachEmail}
                                    </Body3Regular>
                                </Row>
                                <Row style={{ marginLeft: calWidth(16), marginTop: calHeight(8) }}>
                                    <Body3Regular
                                        style={{ width: calWidth(100), height: calHeight(18), color: colors.WHITE_700 }}
                                    >
                                        활성 회원 수 :{item.coachActiveMemberCount}명
                                    </Body3Regular>
                                    <Body3Regular
                                        style={{ width: calWidth(220), height: calHeight(18), color: colors.WHITE_700 }}
                                    >
                                        연락처 : {item.coachPhoneNumber ? item.coachPhoneNumber : '-'}
                                    </Body3Regular>
                                </Row>
                            </Col>
                        );
                    })}
            </Col>
        </Col>
    );
};

export default TrainerManageTop;
