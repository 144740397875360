import React from 'react';
import { Col, Row } from '../../../lib/styled';
import { calHeight, calWidth, truncateString } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Bold, Body2Bold, Body2Regular, CaptionLight, Subtitle1Regular } from '../../../lib/font';
import dayjs from 'dayjs';

const CommunicationListBody = ({ currentItems, onClickDetailPage }: any) => {
    return (
        <Col style={{}}>
            {currentItems ? (
                currentItems.map((item: any, index: number) => {
                    return (
                        <Col
                            key={'mobileComm' + index}
                            onClick={() => onClickDetailPage(item)}
                            style={{
                                width: '100vw',
                                alignItems: 'center',

                                marginBottom: calHeight(16),
                            }}
                        >
                            <Col
                                style={{
                                    width: calWidth(374),
                                    height: calHeight(224),
                                }}
                            >
                                <Row
                                    style={{
                                        width: calWidth(88),
                                        height: calHeight(40),
                                        backgroundColor: item?.status === 'PENDING' ? colors.ERROR : colors.SUCCESS,
                                        position: 'absolute',
                                        borderRadius: 8,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: calWidth(16),
                                    }}
                                >
                                    <Body1Bold style={{ color: colors.WHITE_50 }}>
                                        {item?.status === 'PENDING' ? '답변 대기' : '답변 완료'}
                                    </Body1Bold>
                                </Row>
                                <Col
                                    style={{
                                        width: calWidth(374),
                                        height: calHeight(216),

                                        backgroundColor: colors.WHITE_200,
                                        borderRadius: 8,
                                        boxShadow: '1px 1px 8px 0px rgba(43, 82, 157, 0.18)',
                                        marginTop: calHeight(8),
                                    }}
                                >
                                    <Row style={{ marginTop: calHeight(40), marginLeft: calWidth(24) }}>
                                        <Body2Bold style={{ color: colors.BLUE_700 }}>문의 유형</Body2Bold>
                                        <Body2Regular style={{ color: colors.WHITE_900, marginLeft: calWidth(8) }}>
                                            {item?.category === 'PTFEEDBACK'
                                                ? 'PT 피드백'
                                                : item?.category === 'FACILITY'
                                                ? '시설문의'
                                                : item?.category === 'COUNSEL'
                                                ? '상담문의'
                                                : '기타'}
                                        </Body2Regular>
                                    </Row>

                                    <Row
                                        style={{
                                            marginTop: calHeight(8),
                                            marginLeft: calWidth(24),
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Body2Bold style={{ color: colors.BLUE_700 }}>회원 이름</Body2Bold>
                                        <Body2Regular
                                            style={{ width: 53, color: colors.WHITE_900, marginLeft: calWidth(8) }}
                                        >
                                            {truncateString(item?.memberName, 3)}
                                        </Body2Regular>

                                        <div
                                            style={{
                                                width: 2,
                                                height: 2,
                                                borderRadius: 1,
                                                backgroundColor: colors.BLUE_900,
                                                marginLeft: calWidth(16),
                                                marginRight: calWidth(16),
                                            }}
                                        />
                                        <Body2Bold style={{ color: colors.BLUE_700 }}>강사 이름</Body2Bold>
                                        <Body2Regular style={{ color: colors.WHITE_900, marginLeft: calWidth(8) }}>
                                            {item?.coachName}
                                        </Body2Regular>
                                    </Row>

                                    <CaptionLight
                                        style={{
                                            color: colors.WHITE_700,
                                            marginLeft: calWidth(24),
                                            marginTop: calHeight(8),
                                        }}
                                    >
                                        {dayjs(item.updatedTime).format('YYYY/MM/DD(ddd)/A HH:mm')}
                                    </CaptionLight>

                                    <Col
                                        style={{
                                            width: calWidth(342),
                                            height: calHeight(76),
                                            borderRadius: 4,
                                            backgroundColor: colors.WHITE_300,
                                            boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                                            marginLeft: calWidth(16),
                                            marginTop: calHeight(8),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Body2Regular style={{ width: calWidth(310), color: colors.WHITE_900 }}>
                                            {truncateString(item?.contents[0]?.content, 55)}
                                        </Body2Regular>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                    );
                })
            ) : (
                <Row style={{ width: '100vw', justifyContent: 'center', marginTop: calHeight(50) }}>
                    <Subtitle1Regular style={{ color: colors.WHITE_700 }}>
                        아직 회원에게 온 문의글이 없습니다!
                    </Subtitle1Regular>
                </Row>
            )}
        </Col>
    );
};

export default CommunicationListBody;
