import React, { ChangeEvent, useRef, useState } from 'react';
import { Col, Row } from '../lib/styled';
import { colors } from '../lib/colors';
import { useMutation } from '@tanstack/react-query';
import { requestSignInAPI } from '../api/auth';
import { calHeight, calWidth, setItemWithExpiration } from '../lib/utils';
import { useNavigate } from 'react-router-dom';
import { I_RAPPO_LOGO_SIGN, I_SIGN_BACKGROUND } from '../types/images';
import { Body1Regular, Body3Light, CaptionBold, CaptionLight, Head4 } from '../lib/font';
import NormalButton from '../components/Button/NormalButton';

const SignInPage = ({ setAuthorization }: any) => {
    const navigate = useNavigate();
    const [id, setId] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [idToast, setIdToast] = useState<boolean>(false);
    const [passwordToast, setPassWordToast] = useState<boolean>(false);

    const idRef = useRef<HTMLInputElement | null>(null);
    const passWordRef = useRef<HTMLInputElement | null>(null);

    const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value.trim());
    };

    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value.trim());
    };

    const toastFunction = (type: string) => {
        if (type === 'password') {
            setPassWordToast(true);
            setTimeout(() => {
                setPassWordToast(false);
            }, 2000);
        }

        if (type === 'id') {
            setIdToast(true);
            setTimeout(() => {
                setIdToast(false);
            }, 2000);
        }
    };

    // API (POST) : 관리자 로그인
    const requestSignIn = useMutation({
        mutationFn: async () => await requestSignInAPI(id, password),
        onSuccess: (res) => {
            if (res.status === 200) {
                const accessToken: string = res.data?.tokens?.access?.token;

                const user: any = res.data?.user;
                const center: any = res.data?.center;
                const subscriptionData: any = res.data?.subscriptionData;
                const userObject = {
                    adminId: user?.adminId,
                    id: user?.id,
                    name: user?.name,
                    email: user?.email,
                    gender: user?.gender,
                    phoneNumber: user?.phoneNumber,
                    profileImageUrl: user?.profileImageUrl,
                    testAccount: false, // 데모버전 계정인지아닌지
                };
                const centerObject = {
                    centerAddress: center?.address,
                    centerCategory: center?.category,
                    centerCode: center?.code,
                    centerName: center?.name,
                    centerId: center?.id,
                    isNewCommunicationBox: res.data?.isNewCommunicationBox,
                };

                const planObject = {
                    coachCount: subscriptionData?.coachCount, // 소속 트레이너수
                    memberCount: subscriptionData?.memberCount, // 활성 수강생수
                    activationDate: subscriptionData?.activationDate, // 플랜 활성일
                    expirationDate: subscriptionData?.expirationDate, // 플랜 만료일
                    expirationDelayDate: subscriptionData?.expirationDelayDate, // 플랜 만료후 유예기간
                    finalPaymentDate: subscriptionData?.finalPaymentDate, // 마지막 결제일
                    finalPaymentTryDate: subscriptionData?.finalPaymentTryDate, // 마지막 결제 시도일
                    cardIssuingBank: subscriptionData?.cardIssuingBank, // 결제카드 은행
                    cardNumber: subscriptionData?.cardNumber, // 결제카드 번호
                    planAmount: subscriptionData?.planAmount, // 결제 금액
                    usableStatus: subscriptionData?.usableStatus, // 구독상태
                    paymentDueDate: null,
                    planLimitCount: subscriptionData?.planLimitCount, // 플랜 제한 이용 인원
                    prevPlanLimitCount: subscriptionData?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
                    changedPlanLimitCount: subscriptionData?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트
                    confirmUsableStatus: false, // 구독상태의 따른 팝업 클릭시 12시간이내에 다시안뜨게 하는 변수
                };

                setItemWithExpiration('authorization', accessToken, 12);
                setItemWithExpiration('userObject', userObject, 12);
                setItemWithExpiration('centerObject', centerObject, 12);
                setItemWithExpiration('planObject', planObject, 12);
                setAuthorization(accessToken);

                navigate('/daily');
            }
            if (res.status === 404) {
                // alert('존재하지 않는 아이디 입니다');
                // createAlert('', '존재하지 않는 아이디 입니다');
                toastFunction('id');
                idRef.current && idRef.current.focus();
            }
            if (res.status === 418) {
                // alert('비밀번호가 일치하지 않습니다');
                // createAlert('', '비밀번호가 일치하지 않습니다');
                toastFunction('password');
            }
        },
    });

    const onClickSignInButton = () => {
        requestSignIn.mutate();
    };

    return (
        <Col
            style={{
                alignItems: 'center',
                justifyContent: 'end',
                width: '100vw',
                height: '100vh',
                backgroundImage: `url(${I_SIGN_BACKGROUND})`,
            }}
        >
            <img
                src={I_RAPPO_LOGO_SIGN}
                style={{
                    width: 160,
                    height: 150,
                    marginBottom: calHeight(34),
                }}
            />
            <Col
                style={{
                    alignItems: 'center',
                    width: '100vw',
                    height: calHeight(544),
                    borderTopLeftRadius: 48,
                    borderTopRightRadius: 48,
                    backgroundColor: colors.WHITE_200,
                }}
            >
                <Head4 style={{ marginTop: calHeight(40), marginBottom: calHeight(40), color: colors.BLUE_900 }}>
                    Login
                </Head4>
                <Col style={{ width: calWidth(342), height: calHeight(106), alignItems: 'center' }}>
                    <Body1Regular
                        style={{
                            width: calWidth(342),
                            textAlign: 'left',
                            marginLeft: calWidth(8),
                            color: colors.BLUE_900,
                        }}
                    >
                        아이디
                    </Body1Regular>
                    <input
                        ref={idRef}
                        value={id}
                        onChange={onChangeId}
                        style={{
                            width: calWidth(342),
                            height: calHeight(56),
                            backgroundColor: colors.WHITE_300,
                            borderRadius: 48,
                            boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                            marginTop: calHeight(4),
                            marginBottom: calHeight(4),
                            paddingLeft: calWidth(24),
                            boxSizing: 'border-box',
                            borderWidth: idToast ? 2 : 0,
                            outline: 'none',
                            borderColor: idToast ? colors.ERROR : colors.PRIMARY_BLUE,
                            fontSize: 14,
                            fontWeight: 400,
                            userSelect: 'inherit',
                        }}
                        autoComplete="off"
                    />
                    {idToast && (
                        <Body3Light style={{ width: calWidth(342), textAlign: 'left', color: colors.ERROR }}>
                            *아이디가 존재하지 않습니다
                        </Body3Light>
                    )}
                </Col>
                <Col style={{ width: calWidth(342), height: calHeight(106), alignItems: 'center' }}>
                    <Body1Regular
                        style={{
                            width: calWidth(342),
                            textAlign: 'left',
                            marginLeft: calWidth(8),
                            color: colors.BLUE_900,
                        }}
                    >
                        비밀번호
                    </Body1Regular>
                    <input
                        ref={passWordRef}
                        value={password}
                        onChange={onChangePassword}
                        type="password"
                        style={{
                            width: calWidth(342),
                            height: calHeight(56),
                            backgroundColor: colors.WHITE_300,
                            borderRadius: 48,
                            boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                            marginTop: calHeight(4),
                            paddingLeft: calWidth(24),
                            boxSizing: 'border-box',
                            borderWidth: passwordToast ? 2 : 0,
                            outline: 'none',
                            borderColor: passwordToast ? colors.ERROR : colors.PRIMARY_BLUE,
                            fontSize: 14,
                            fontWeight: 400,
                            userSelect: 'inherit',
                        }}
                        autoComplete="off"
                    />
                    {passwordToast && (
                        <Body3Light style={{ width: calWidth(342), textAlign: 'left', color: colors.ERROR }}>
                            *비밀번호가 일치하지 않습니다
                        </Body3Light>
                    )}
                </Col>
                <NormalButton
                    style={{ width: calWidth(342), height: calHeight(56), marginTop: calHeight(48) }}
                    text={'로그인'}
                    onClick={onClickSignInButton}
                />
                <CaptionLight style={{ color: colors.WHITE_800, marginTop: calHeight(32) }}>
                    아이디 or 비밀번호를 잊으셨다면 <CaptionBold>1:1 상담문의</CaptionBold> 부탁드립니다
                </CaptionLight>
            </Col>
        </Col>
    );
};

export default SignInPage;
