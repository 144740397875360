import React from 'react';
import { Col, Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { I_BACK } from '../../types/images';
import { Body1Bold, Body1Regular, Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';

type Props = {
    step: number;
    onClickExit: () => void;
    onClickPrevStep: () => void;
};

const TrainerRegisterHeader = ({ step, onClickExit, onClickPrevStep }: Props) => {
    return (
        <Col>
            <Row
                style={{
                    width: '100vw',
                    height: calHeight(56),
                    boxShadow: '1px 1px 8px 0px #2B529D2E',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {step === 1 ? (
                    <Col
                        style={{
                            width: calWidth(56),
                            height: calWidth(56),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    ></Col>
                ) : (
                    <Col
                        style={{
                            width: calWidth(56),
                            height: calWidth(56),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={onClickPrevStep}
                    >
                        <img src={I_BACK} style={{ width: 20, height: 18 }} />
                    </Col>
                )}

                <Subtitle1Bold style={{ color: colors.BLUE_900 }}>트레이너 등록하기</Subtitle1Bold>
                <Col
                    onClick={onClickExit}
                    style={{ width: calWidth(48), height: calWidth(56), justifyContent: 'center' }}
                >
                    <Body1Regular style={{ color: colors.BLUE_900 }}>닫기</Body1Regular>
                </Col>
            </Row>

            <Row style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', marginTop: calHeight(32) }}>
                <Col
                    style={{
                        width: 36,
                        height: 36,
                        borderRadius: 18,
                        backgroundColor: step === 1 ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Body1Bold style={{ color: colors.WHITE_50 }}>1</Body1Bold>
                </Col>
                <div style={{ width: 60, height: 1, backgroundColor: colors.BLUE_100 }} />
                <Col
                    style={{
                        width: 36,
                        height: 36,
                        borderRadius: 18,
                        backgroundColor: step === 2 ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Body1Bold style={{ color: colors.WHITE_50 }}>2</Body1Bold>
                </Col>
                <div style={{ width: 60, height: 1, backgroundColor: colors.BLUE_100 }} />
                <Col
                    style={{
                        width: 36,
                        height: 36,
                        borderRadius: 18,
                        backgroundColor: step === 3 ? colors.PRIMARY_BLUE : colors.BLUE_100,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Body1Bold style={{ color: colors.WHITE_50 }}>3</Body1Bold>
                </Col>
            </Row>
        </Col>
    );
};

export default TrainerRegisterHeader;
