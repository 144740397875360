import { Col, Row } from '../lib/styled';
import { colors } from '../lib/colors';
import { Body1Regular, Subtitle1Bold } from '../lib/font';
import { calHeight, calWidth } from '../lib/utils';
import { useNavigate } from 'react-router-dom';

const TermPage = () => {
    const navigate = useNavigate();

    return (
        <Col style={{ width: '100vw', height: '100dvh', backgroundColor: colors.WHITE_200 }}>
            <Row
                style={{
                    alignItems: 'center',
                    height: calHeight(56),

                    boxShadow: '1px 1px 8px 0px #2B529D2E',
                }}
            >
                <Subtitle1Bold
                    style={{
                        width: calWidth(148),
                        height: calHeight(28),
                        marginLeft: calWidth(121),
                        color: colors.BLUE_900,
                    }}
                >
                    이용약관 / 개인정보
                </Subtitle1Bold>
                <Row
                    onClick={() => {
                        navigate(-1);
                    }}
                    style={{ width: calWidth(32), height: calHeight(24), marginLeft: calWidth(73) }}
                >
                    <Body1Regular>닫기</Body1Regular>
                </Row>
            </Row>

            <Col>
                <iframe
                    title="MyIframe"
                    src={'https://s3.ap-northeast-2.amazonaws.com/terms.rappomanager.com/index.html'}
                    style={{ marginTop: 10, border: 'none', height: '90dvh', backgroundColor: colors.WHITE_200 }}
                />
            </Col>
        </Col>
    );
};

export default TermPage;
