import React from 'react';
import { Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import dayjs from 'dayjs';
import { Body3Bold, Body3Regular } from '../../lib/font';
import { colors } from '../../lib/colors';

const DailyScheduleLayout = () => {
    let grid: any = Array.from({ length: 24 }, (_, rowIndex) =>
        Array.from({ length: 5 }, (_, colIndex) => ({
            id: `${rowIndex}-${colIndex}`,
            content: colIndex === 0 ? `${rowIndex}시` : `${rowIndex}-${colIndex}`,
            time: rowIndex,
            schedules: [],
        }))
    );

    return (
        <>
            {grid.map((row: any, rowIndex: any) => (
                <Row key={rowIndex}>
                    {row.map((cell: any, cellIndex: any) => {
                        return cellIndex === 0 ? (
                            <Row
                                key={cell.id}
                                style={{
                                    width: calWidth(42),
                                    height: 96,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {parseInt(dayjs().format('H')) === rowIndex ? (
                                    <Body3Bold style={{ color: colors.BLUE_900 }}>{cell.content}</Body3Bold>
                                ) : parseInt(dayjs().format('H')) + 1 === rowIndex ? (
                                    <Body3Regular style={{ color: colors.BLUE_700 }}>{cell.content}</Body3Regular>
                                ) : (
                                    <Body3Regular style={{ color: colors.WHITE_900 }}>{cell.content}</Body3Regular>
                                )}
                            </Row>
                        ) : (
                            <Row
                                key={cell.id}
                                style={{
                                    width: calWidth(88),
                                    height: 94,
                                    border: '1px solid #A6BFEF',
                                }}
                            ></Row>
                        );
                    })}
                </Row>
            ))}
        </>
    );
};

export default DailyScheduleLayout;
