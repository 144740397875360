import React from 'react';
import { Col, Row } from '../../lib/styled';
import { calHeightToDvh, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_EMPTY_PROFILE } from '../../types/images';
import { Body1Bold, Body3Bold, CaptionRegular } from '../../lib/font';

const SideBarProfile = ({ userObject, centerObject, planObject }: any) => {
    return (
        <>
            <Col
                style={{
                    width: calWidth(258),
                    height: calHeightToDvh(120),
                    borderRadius: 36,
                    backgroundColor: colors.WHITE_300,
                    boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                    marginTop: calHeightToDvh(32),
                    justifyContent: 'center',
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <Col
                        style={{
                            width: calWidth(60),
                            height: calWidth(60),
                            borderRadius: 30,
                            marginLeft: calWidth(16),
                        }}
                    >
                        {userObject?.profileImageUrl ? (
                            <img
                                style={{
                                    width: calWidth(60),
                                    height: calWidth(60),
                                    borderRadius: 30,
                                }}
                                src={userObject?.profileImageUrl}
                                onError={(e: any) => {
                                    e.currentTarget.src = I_EMPTY_PROFILE;
                                }}
                            />
                        ) : (
                            <img
                                style={{
                                    width: calWidth(60),
                                    height: calWidth(60),
                                    borderRadius: 30,
                                }}
                                src={I_EMPTY_PROFILE}
                            />
                        )}
                    </Col>
                    <Col style={{ marginLeft: calWidth(8) }}>
                        <Body3Bold style={{ color: colors.BLUE_900, marginBottom: calHeightToDvh(2) }}>
                            {centerObject?.centerName}
                        </Body3Bold>
                        <Body1Bold style={{ color: colors.BLUE_900, marginBottom: calHeightToDvh(2) }}>
                            {userObject?.name} 매니저
                        </Body1Bold>
                        <CaptionRegular style={{ color: colors.BLUE_700 }}>
                            {planObject?.usableStatus === 'trial'
                                ? '무료 체험중'
                                : `${planObject?.planLimitCount} 인 플랜 이용중`}
                        </CaptionRegular>
                    </Col>
                </Row>
            </Col>
            <CaptionRegular style={{ color: colors.WHITE_900, marginTop: calHeightToDvh(16) }}>
                Rappo Manager는 PC로 사용하기를 권장합니다!
            </CaptionRegular>
        </>
    );
};

export default SideBarProfile;
