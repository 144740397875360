import React from 'react';
import { Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import NormalDropdown from '../../Dropdown/NormalDropdown';
import { Body2Regular } from '../../../lib/font';

const CommunicationListHeader = ({
    toggleAnswerDropDown,
    answerSortType,
    isOpenAnswer,
    answerSortOptions,
    handleAnswerSelect,
    totalNumberCommunication,
}: any) => {
    return (
        <Row
            style={{
                justifyContent: 'space-between',
                marginTop: calHeight(24),
                alignItems: 'center',
                marginLeft: calWidth(16),
                marginRight: calWidth(16),
            }}
        >
            <NormalDropdown
                toggleSortDropDown={toggleAnswerDropDown}
                sortType={answerSortType}
                isOpenSort={isOpenAnswer}
                sortOptions={answerSortOptions}
                handleSortSelect={handleAnswerSelect}
            />
            <Body2Regular>총 문의 수 {totalNumberCommunication}개</Body2Regular>
        </Row>
    );
};

export default CommunicationListHeader;
