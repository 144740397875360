import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../lib/styled';
import { calHeightToDvh, calWidth } from '../../lib/utils';
import { NavLink, NavigateProps, useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../lib/colors';
import { CaptionRegular, Subtitle1Bold, Subtitle1Regular } from '../../lib/font';

type Props = {
    onClickSideBarButton: () => void;
    navigate: any;
    centerObject: any;
};

const SideBarButtonList = ({ onClickSideBarButton, navigate, centerObject }: Props) => {
    const location = useLocation();

    const [isDaily, setIsDaily] = useState<boolean>(false);
    const [isWeekly, setIsWeekly] = useState<boolean>(false);
    const [isTrainerManage, setIsTrainerManage] = useState<boolean>(false);
    const [isCommunication, setIsCommunication] = useState<boolean>(false);

    const onClickNavigate = (to: string) => {
        onClickSideBarButton();
        navigate(to);
    };

    useEffect(() => {
        if (location) {
            if (location.pathname.includes('daily')) {
                setIsDaily(true);
                setIsWeekly(false);
                setIsTrainerManage(false);
                setIsCommunication(false);
            }

            if (location.pathname.includes('weekly')) {
                setIsDaily(false);
                setIsWeekly(true);
                setIsTrainerManage(false);
                setIsCommunication(false);
            }

            if (location.pathname.includes('manage')) {
                setIsDaily(false);
                setIsWeekly(false);
                setIsTrainerManage(true);
                setIsCommunication(false);
            }

            if (location.pathname.includes('communication')) {
                setIsDaily(false);
                setIsWeekly(false);
                setIsTrainerManage(false);
                setIsCommunication(true);
            }
        }
    }, [location]);

    return (
        <Col style={{ width: calWidth(290), marginTop: calHeightToDvh(24) }}>
            <Row
                style={{
                    width: calWidth(290),
                    height: calHeightToDvh(56),
                    backgroundColor: isDaily ? colors.WHITE_500 : colors.WHITE_200,
                    alignItems: 'center',
                    borderTop: isDaily ? 0 : `1px solid ${colors.BLUE_100}`,
                    borderBottom: isDaily ? 0 : `1px solid ${colors.BLUE_100}`,
                }}
                onClick={() => {
                    onClickNavigate('/daily');
                }}
            >
                {isDaily ? (
                    <Subtitle1Bold style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        일간 강사 스케줄
                    </Subtitle1Bold>
                ) : (
                    <Subtitle1Regular style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        일간 강사 스케줄
                    </Subtitle1Regular>
                )}
            </Row>

            <Row
                style={{
                    width: calWidth(290),
                    height: calHeightToDvh(56),
                    backgroundColor: isWeekly ? colors.WHITE_500 : colors.WHITE_200,
                    marginTop: calHeightToDvh(16),
                    alignItems: 'center',
                    borderTop: isWeekly ? 0 : `1px solid ${colors.BLUE_100}`,
                    borderBottom: isWeekly ? 0 : `1px solid ${colors.BLUE_100}`,
                }}
                onClick={() => {
                    onClickNavigate('/weekly');
                }}
            >
                {isWeekly ? (
                    <Subtitle1Bold style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        주간 강사 스케줄
                    </Subtitle1Bold>
                ) : (
                    <Subtitle1Regular style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        주간 강사 스케줄
                    </Subtitle1Regular>
                )}
            </Row>
            <Row
                style={{
                    width: calWidth(290),
                    height: calHeightToDvh(56),
                    backgroundColor: isTrainerManage ? colors.WHITE_500 : colors.WHITE_200,
                    marginTop: calHeightToDvh(16),
                    alignItems: 'center',
                    borderTop: isTrainerManage ? 0 : `1px solid ${colors.BLUE_100}`,
                    borderBottom: isTrainerManage ? 0 : `1px solid ${colors.BLUE_100}`,
                }}
                onClick={() => {
                    onClickNavigate('/manage');
                }}
            >
                {isTrainerManage ? (
                    <Subtitle1Bold style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        트레이너 관리
                    </Subtitle1Bold>
                ) : (
                    <Subtitle1Regular style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        트레이너 관리
                    </Subtitle1Regular>
                )}
            </Row>

            <Row
                style={{
                    width: calWidth(290),
                    height: calHeightToDvh(56),
                    backgroundColor: isCommunication ? colors.WHITE_500 : colors.WHITE_200,
                    marginTop: calHeightToDvh(16),
                    alignItems: 'center',
                    borderTop: isCommunication ? 0 : `1px solid ${colors.BLUE_100}`,
                    borderBottom: isCommunication ? 0 : `1px solid ${colors.BLUE_100}`,
                }}
                onClick={() => {
                    onClickNavigate('/communication');
                }}
            >
                {isCommunication ? (
                    <Subtitle1Bold style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        회원 소통 상자
                    </Subtitle1Bold>
                ) : (
                    <Subtitle1Regular style={{ color: colors.PRIMARY_BLUE, marginLeft: calWidth(24) }}>
                        회원 소통 상자
                    </Subtitle1Regular>
                )}

                {centerObject?.isNewCommunicationBox && (
                    <Row style={{ height: calHeightToDvh(56) }}>
                        <Row
                            style={{
                                width: 12,
                                height: 12,
                                borderRadius: 6,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: colors.ERROR,
                            }}
                        >
                            <CaptionRegular style={{ color: colors.WHITE_50 }}>N</CaptionRegular>
                        </Row>
                    </Row>
                )}
            </Row>
        </Col>
    );
};

export default SideBarButtonList;
