export const colors = {
    // 승연님 디자인 시스템 컬러
    ERROR: '#FF3E4A',
    ERROR2: '#FFCCDF',
    SUCCESS: '#00A793',
    CHANGE: '#FF8777',
    BLACK: '#1B1E31',
    BLUE_100: '#C3D4F4',
    BLUE_200: '#A6BFEF',
    BLUE_300: '#7DA1E8',
    BLUE_400: '#648FE4',
    BLUE_600: '#3869C9',
    BLUE_700: '#2B529D',
    BLUE_800: '#223F7A',
    BLUE_900: '#1A305D',
    PRIMARY_BLUE: '#3D73DD',
    WHITE_50: '#FDFEFF',
    WHITE_100: '#F8FBFF',
    WHITE_200: '#F5F9FF',
    WHITE_300: '#F0F6FF',
    WHITE_400: '#EDF4FF',
    WHITE_500: '#E9F1FF',
    WHITE_600: '#D4DBE8',
    WHITE_700: '#A5ABB5',
    WHITE_800: '#80858C',
    WHITE_900: '#62656B',
};

export const WHITE = '#ffffff';
