import styled from 'styled-components';
const width = window.innerWidth;
const isSmall = width < 390 ? 'true' : 'false';

export const Head1 = styled.span`
    font-size: 42px !important;
    /* line-height: 64px; */
    font-weight: 800;
`;

export const Head2 = styled.span`
    font-size: 36px !important;
    /* line-height: 54px; */
    font-weight: 700;
`;
export const Head3 = styled.span`
    font-size: 32px !important;
    /* line-height: 48px; */
    font-weight: 700;
`;
export const Head4 = styled.span`
    font-size: 24px !important;
    /* line-height: 36px; */
    font-weight: 700;
`;

export const Subtitle1Bold = styled.span`
    font-size: ${isSmall === 'true' ? '17px !important;' : '18px !important;'};
    /* line-height: 28px; */
    font-weight: 700;
`;

export const Subtitle1Regular = styled.span`
    font-size: ${isSmall === 'true' ? '17px !important;' : '18px !important;'};
    /* line-height: 28px; */
    font-weight: 400;
`;

export const Body1Bold: any = styled.span`
    font-size: ${isSmall === 'true' ? '14px !important;' : '16px !important;'};
    /* line-height: 24px; */
    font-weight: 700;
`;
export const Body1Regular = styled.span`
    font-size: ${isSmall === 'true' ? '14px !important;' : '16px !important;'};
    /* line-height: 24px; */
    font-weight: 400;
`;
export const Body1Light = styled.span`
    font-size: ${isSmall === 'true' ? '14px !important;' : '16px !important;'};
    /* line-height: 24px; */
    font-weight: 300;
`;

export const Body2Bold = styled.span`
    font-size: ${isSmall === 'true' ? '12px !important;' : '14px !important;'};
    /* line-height: 22px; */
    font-weight: 700;
`;
export const Body2Regular = styled.span`
    font-size: ${isSmall === 'true' ? '12px !important;' : '14px !important;'};
    /* line-height: 22px; */
    font-weight: 400;
`;
export const Body2Light = styled.span`
    font-size: ${isSmall === 'true' ? '12px !important;' : '14px !important;'};
    /* line-height: 22px; */
    font-weight: 300;
`;

export const Body3Bold = styled.span`
    font-size: ${isSmall === 'true' ? '10px !important;' : '12px !important;'};
    /* line-height: 18px; */
    font-weight: 700;
`;

export const Body3Regular = styled.span`
    font-size: ${isSmall === 'true' ? '10px !important;' : '12px !important;'};
    /* line-height: 18px; */
    font-weight: 400;
`;
export const Body3Light = styled.span`
    font-size: ${isSmall === 'true' ? '10px !important;' : '12px !important;'};
    /* line-height: 18px; */
    font-weight: 300;
`;

export const CaptionBold = styled.span`
    font-size: ${isSmall === 'true' ? '9px !important;' : '10px !important;'};
    /* line-height: 16px; */
    font-weight: 700;
`;

export const CaptionRegular = styled.span`
    font-size: ${isSmall === 'true' ? '9px !important;' : '10px !important;'};
    /* line-height: 16px; */
    font-weight: 400;
`;
export const CaptionLight: any = styled.span`
    font-size: ${isSmall === 'true' ? '9px !important;' : '10px !important;'};
    /* line-height: 16px; */
    font-weight: 300;
`;

export const OverlineBold = styled.span`
    font-size: ${isSmall === 'true' ? '7px !important;' : '8px !important;'};
    /* line-height: 12px; */
    font-weight: 700;
`;

export const OverlineRegular = styled.span`
    font-size: ${isSmall === 'true' ? '7px !important;' : '8px !important;'};
    /* line-height: 12px; */
    font-weight: 400;
`;
export const OverlineLight = styled.span`
    font-size: ${isSmall === 'true' ? '7px !important;' : '8px !important;'};
    /* line-height: 12px; */
    font-weight: 300;
`;
