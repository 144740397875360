import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { I_LEFT_PRIMARY, I_LEFT_PRIMARY_TRIANGLE, I_RIGHT_PRIMARY, I_RIGHT_PRIMARY_TRIANGLE } from '../../types/images';
import { Body1Bold, Body3Bold, CaptionBold, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';
import DaysList from './WekklyScheduleBody/DaysList';

type Props = {
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
    selectedDay: Date;
    onClickAddDate: () => void;
    onClickSubDate: () => void;
    scheduleCount: number;
    onClickPrevDate: () => void;
    onClickNextDate: () => void;
    dayIndex: number;
    scheduleList: any;
};
const WeeklyScheduleTop = ({
    setIsCalendar,
    selectedDay,
    onClickAddDate,
    onClickSubDate,
    scheduleCount,
    onClickPrevDate,
    onClickNextDate,
    dayIndex,
    scheduleList,
}: Props) => {
    return (
        <Col style={{ position: 'fixed', top: calHeight(55.5), zIndex: 3, backgroundColor: colors.WHITE_300 }}>
            <Body1Bold
                style={{
                    // width: calWidth(109),
                    height: calHeight(24),
                    color: colors.BLUE_700,
                    marginTop: calHeight(16),
                    marginLeft: calWidth(16),
                }}
            >
                이번주 수업({scheduleCount})
            </Body1Bold>
            <CaptionRegular
                style={{
                    width: calWidth(358),
                    height: calHeight(32),
                    marginTop: calHeight(8),
                    color: colors.WHITE_800,
                    marginLeft: calWidth(16),
                    marginRight: calWidth(16),
                }}
            >
                <CaptionBold>오늘 예정된 수업 스케줄을 확인</CaptionBold>할 수 있으며, 새롭게 수업 예약이 가능한 시간을
                확인할 수 있습니다.
            </CaptionRegular>

            <Row style={{ marginTop: calHeight(8), marginLeft: calWidth(16) }}>
                <Row style={{ height: calHeight(16), alignItems: 'center' }}>
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 4,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                    />

                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        확정 수업 시간
                    </CaptionRegular>
                </Row>

                <Row
                    style={{
                        // width: calWidth(78),
                        height: calHeight(16),
                        marginLeft: calWidth(12),
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 4,
                            backgroundColor: colors.BLUE_100,
                        }}
                    />
                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        개인 일정
                    </CaptionRegular>
                </Row>

                <Row
                    style={{
                        // width: calWidth(55),
                        height: calHeight(16),
                        marginLeft: calWidth(12),
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            border: '1.6px solid #7DA1E8',
                            borderRadius: 4,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                    />

                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        현재 수업 중
                    </CaptionRegular>
                </Row>
            </Row>

            <Row style={{ width: '100vw', alignItems: ' center', marginTop: 10, justifyContent: 'space-between' }}>
                <Row
                    style={{
                        width: calWidth(46),
                        height: calWidth(46),
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickPrevDate}
                >
                    {dayIndex > 0 && (
                        <>
                            <img src={I_LEFT_PRIMARY} />
                            <img src={I_LEFT_PRIMARY} />
                        </>
                    )}
                </Row>

                <Row
                    style={{
                        width: calWidth(248),

                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Row
                        style={{
                            width: 46,
                            height: 46,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={onClickSubDate}
                    >
                        <img style={{ width: 46, height: 46 }} src={I_LEFT_PRIMARY_TRIANGLE} />
                    </Row>
                    <Body3Bold
                        onClick={() => {
                            setIsCalendar(true);
                        }}
                        style={{
                            width: calWidth(148),
                            textAlign: 'center',
                            color: colors.PRIMARY_BLUE,
                            marginLeft: 4,
                            marginRight: 4,
                        }}
                    >
                        {dayjs(selectedDay).startOf('week').format('YYYY년MM월DD ~ ')}
                        {dayjs(selectedDay).endOf('week').format('DD일')}
                    </Body3Bold>
                    <Row
                        style={{
                            width: 46,
                            height: 46,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={onClickAddDate}
                    >
                        <img style={{ width: 46, height: 46 }} src={I_RIGHT_PRIMARY_TRIANGLE} />
                    </Row>
                </Row>

                <Row
                    style={{
                        width: calWidth(46),
                        height: calWidth(46),
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickNextDate}
                >
                    {dayIndex < 4 && (
                        <>
                            <img src={I_RIGHT_PRIMARY} />
                            <img src={I_RIGHT_PRIMARY} />
                        </>
                    )}
                </Row>
            </Row>

            <DaysList scheduleList={scheduleList} />
        </Col>
    );
};

export default WeeklyScheduleTop;
