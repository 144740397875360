import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from '../../lib/styled';
import { calHeight, calWidth, getItemWithExpiration, setItemWithExpiration } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_GREY_EXIT, I_RED_ERROR } from '../../types/images';
import { Body1Bold, Body2Light, Head4, Subtitle1Bold } from '../../lib/font';
import dayjs from 'dayjs';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`;

type Props = {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
};
const ModalPayPending = ({ modalVisible, setModalVisible }: Props) => {
    const [planObject, setPlanObject] = useState(getItemWithExpiration('planObject'));

    const onClickExit = () => {
        const planObjectData = {
            coachCount: planObject?.coachCount, // 소속 트레이너수
            memberCount: planObject?.memberCount, // 활성 수강생수
            activationDate: planObject?.activationDate, // 플랜 활성일
            expirationDate: planObject?.expirationDate, // 플랜 만료일
            expirationDelayDate: planObject?.expirationDelayDate, // 플랜 만료후 유예기간
            finalPaymentDate: planObject?.finalPaymentDate, // 마지막 결제일
            finalPaymentTryDate: planObject?.finalPaymentTryDate, // 마지막 결제 시도일
            cardIssuingBank: planObject?.cardIssuingBank, // 결제카드 은행
            cardNumber: planObject?.cardNumber, // 결제카드 번호
            planAmount: planObject?.planAmount, // 결제 금액
            usableStatus: planObject?.usableStatus, // 구독상태
            paymentDueDate: null,
            planLimitCount: planObject?.planLimitCount, // 플랜 제한 이용 인원
            prevPlanLimitCount: planObject?.prevPlanLimitCount, // 구독 인원 변경시 변경전 플랜카운트
            changedPlanLimitCount: planObject?.changedPlanLimitCount, // 구독 인원 변경시 변경 플랜카운트
            confirmUsableStatus: true, // 구독상태의 따른 팝업 클릭시 12시간이내에 다시안뜨게 하는 변수
        };

        setItemWithExpiration('planObject', planObjectData, 12);
        setModalVisible(false);
    };

    return (
        <ModalOverLay>
            <Col
                style={{
                    width: calWidth(358),
                    height: calHeight(402),
                    borderRadius: 24,
                    backgroundColor: colors.WHITE_50,
                    alignItems: 'center',
                }}
            >
                <Row
                    style={{
                        width: calWidth(310),
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: calHeight(24),
                    }}
                >
                    <Row style={{ width: calWidth(225), alignItems: 'center' }}>
                        <img src={I_RED_ERROR} style={{ width: calWidth(24), height: calWidth(24) }} />
                        <Head4 style={{ marginLeft: calWidth(8), color: colors.BLUE_900 }}>구독 결제 갱신 요청</Head4>
                    </Row>
                    <img
                        onClick={onClickExit}
                        src={I_GREY_EXIT}
                        style={{ width: calWidth(28), height: calWidth(28) }}
                    />
                </Row>
                <Col style={{ width: calWidth(310), marginTop: calHeight(36) }}>
                    <Subtitle1Bold style={{ color: colors.BLACK }}>주상화 매니저님!</Subtitle1Bold>
                    <Subtitle1Bold style={{ color: colors.BLACK }}>
                        {planObject.usableStatus === 'needPlanToFree'
                            ? `${dayjs(planObject.expirationDate).format('MM월 DD일')}부로 무료체험 이용기간이`
                            : `${dayjs(planObject.expirationDate).format('MM월 DD일')}부로 구독 결제가`}
                    </Subtitle1Bold>
                    <Subtitle1Bold style={{ color: colors.BLACK }}>
                        {planObject.usableStatus === 'needPlanToFree' ? '만료되었습니다' : '갱신되지 않았습니다'}
                    </Subtitle1Bold>
                    <Body2Light style={{ color: colors.BLACK, marginTop: calHeight(36) }}>
                        {`${dayjs(planObject.expirationDelayDate).format(
                            'MM월 DD일'
                        )}일 내에 PC 라포 매니저에서 구독을 진행해주시길 바랍니다`}
                    </Body2Light>
                </Col>

                <Row
                    style={{
                        width: calWidth(148),
                        height: calHeight(48),
                        borderRadius: 48,
                        border: '1px solid #3D73DD',
                        marginTop: calHeight(70),
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={onClickExit}
                >
                    <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>확인</Body1Bold>
                </Row>
            </Col>
        </ModalOverLay>
    );
};

export default ModalPayPending;
