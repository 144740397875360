import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row } from '../../lib/styled';
import { Body1Bold, Body3Bold, CaptionBold, CaptionRegular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { calHeight, calWidth } from '../../lib/utils';
import { I_LEFT_PRIMARY, I_LEFT_PRIMARY_TRIANGLE, I_RIGHT_PRIMARY, I_RIGHT_PRIMARY_TRIANGLE } from '../../types/images';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');
type Props = {
    dailyCurrentData: any;
    handlePrevPage: () => void;
    handleNextPage: () => void;
    endPage: number;
    currentPage: number;
    setIsCalendar: Dispatch<SetStateAction<boolean>>;
    selectedDay: any;
    onClickAddDate: () => void;
    onClickSubDate: () => void;
    dailyScheduleCount: number;
};

const DailyScheduleTop = ({
    dailyCurrentData,
    handlePrevPage,
    handleNextPage,
    endPage,
    currentPage,
    setIsCalendar,
    selectedDay,
    onClickAddDate,
    onClickSubDate,
    dailyScheduleCount,
}: Props) => {
    return (
        <Col
            style={{
                position: 'fixed',
                height: calHeight(206),
                top: calHeight(56),
                zIndex: 3,
                backgroundColor: colors.WHITE_300,
            }}
        >
            <Body1Bold
                style={{
                    // width: calWidth(109),
                    height: calHeight(24),
                    color: colors.BLUE_700,
                    marginTop: calHeight(16),
                    marginLeft: calWidth(16),
                }}
            >
                오늘의 수업({dailyScheduleCount})
            </Body1Bold>
            <CaptionRegular
                style={{
                    width: calWidth(358),
                    height: calHeight(32),
                    marginTop: calHeight(8),
                    color: colors.WHITE_800,
                    marginLeft: calWidth(16),
                    marginRight: calWidth(16),
                }}
            >
                <CaptionBold>오늘 예정된 수업 스케줄을 확인</CaptionBold>할 수 있으며, 새롭게 수업 예약이 가능한 시간을
                확인할 수 있습니다.
            </CaptionRegular>

            <Row style={{ marginTop: calHeight(8), marginLeft: calWidth(16) }}>
                <Row style={{ height: calHeight(16), alignItems: 'center' }}>
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            border: '1.6px solid #7DA1E8',
                            borderRadius: 4,
                            backgroundColor: colors.WHITE_50,
                        }}
                    />
                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        현재 수업 중
                    </CaptionRegular>
                </Row>

                <Row
                    style={{
                        // width: calWidth(78),
                        height: calHeight(16),
                        marginLeft: calWidth(12),
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 4,
                            backgroundColor: colors.WHITE_50,
                            boxShadow: '1px 1px 8px 0px #2B529D2E',
                        }}
                    />
                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        확정 수업 시간
                    </CaptionRegular>
                </Row>

                <Row
                    style={{
                        // width: calWidth(55),
                        height: calHeight(16),
                        marginLeft: calWidth(12),
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 4,
                            backgroundColor: colors.BLUE_100,
                        }}
                    />
                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        기타 일정
                    </CaptionRegular>
                </Row>

                <Row
                    style={{
                        // width: calWidth(99),
                        height: calHeight(16),
                        marginLeft: calWidth(12),
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 4,
                            backgroundColor: colors.WHITE_500,
                            boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                        }}
                    />
                    <CaptionRegular style={{ color: colors.WHITE_900, marginLeft: calWidth(4) }}>
                        수업 추가 가능 시간
                    </CaptionRegular>
                </Row>
            </Row>

            <Row style={{ width: '100vw', alignItems: ' center', marginTop: 10, justifyContent: 'space-between' }}>
                {currentPage !== 1 ? (
                    <Row
                        style={{
                            width: calWidth(46),
                            height: calWidth(46),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handlePrevPage}
                    >
                        <img src={I_LEFT_PRIMARY} />
                        <img src={I_LEFT_PRIMARY} />
                    </Row>
                ) : (
                    <Row
                        style={{
                            width: calWidth(46),
                            height: calWidth(46),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                )}

                <Row style={{ width: calWidth(232), justifyContent: 'center', alignItems: 'center' }}>
                    <Row onClick={onClickSubDate} style={{ width: 46, height: 46 }}>
                        <img src={I_LEFT_PRIMARY_TRIANGLE} />
                    </Row>
                    <Body3Bold
                        onClick={() => {
                            setIsCalendar(true);
                        }}
                        style={{ width: calWidth(128), color: colors.PRIMARY_BLUE, marginLeft: 4, marginRight: 4 }}
                    >
                        {dayjs(selectedDay).format('YYYY년 MM월 DD일(ddd)')}
                    </Body3Bold>
                    <Row onClick={onClickAddDate} style={{ width: 46, height: 46 }}>
                        <img src={I_RIGHT_PRIMARY_TRIANGLE} />
                    </Row>
                </Row>
                {currentPage !== endPage && endPage !== 0 ? (
                    <Row
                        style={{
                            width: calWidth(46),
                            height: calWidth(46),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleNextPage}
                    >
                        <>
                            <img src={I_RIGHT_PRIMARY} />
                            <img src={I_RIGHT_PRIMARY} />
                        </>
                    </Row>
                ) : (
                    <Row
                        style={{
                            width: calWidth(46),
                            height: calWidth(46),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                )}
            </Row>

            <Row
                style={{
                    width: '100vw',
                    height: calHeight(46),
                    backgroundColor: colors.WHITE_100,
                    boxShadow: '1px 1px 8px 0px #2B529D2E',
                    alignItems: 'center',
                    // paddingLeft: calWidth(42),
                }}
            >
                <Row style={{ width: calWidth(41), height: calHeight(46) }} />
                <Row style={{ height: calHeight(46), alignItems: 'center' }}>
                    {dailyCurrentData &&
                        dailyCurrentData.map((item: any, index: number) => {
                            return (
                                <Row
                                    key={'header' + index}
                                    style={{
                                        width: calWidth(86.4),
                                        height: calHeight(20),
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRight: index === 3 ? '0' : '1px solid #A6BFEF',
                                    }}
                                >
                                    <Body3Bold
                                        style={{
                                            color: colors.BLUE_700,
                                        }}
                                    >
                                        {item.coachName}
                                    </Body3Bold>
                                </Row>
                            );
                        })}
                </Row>
            </Row>
        </Col>
    );
};

export default DailyScheduleTop;
