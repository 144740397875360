import React from 'react';
import { Row } from '../../lib/styled';
import { calHeightToDvh, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_PRIMARY_PLUS } from '../../types/images';
import { Body2Bold } from '../../lib/font';

const AddTrainerButton = ({ navigate, onClickSideBarButton }: any) => {
    return (
        <Row style={{ width: calWidth(290) }}>
            <Row
                style={{
                    width: 178,
                    height: 38,
                    marginTop: calHeightToDvh(56),
                    marginLeft: calWidth(16),
                    alignItems: 'center',
                    borderRadius: 48,
                    // border: '2px solid',

                    // borderImageSource: 'linear-gradient(102.5deg, #1A305D 29.42%, #497ADA 66.6%)',

                    background:
                        'linear-gradient(273.53deg, #223F7A 12.73%, #3869C9 94.19%),linear-gradient(102.5deg, #1A305D 29.42%, #497ADA 66.6%)',
                }}
                onClick={() => {
                    onClickSideBarButton();
                    navigate('/register');
                }}
            >
                <img src={I_PRIMARY_PLUS} style={{ width: 24, height: 24, marginLeft: calWidth(20) }} />
                <Body2Bold style={{ color: colors.WHITE_300, marginLeft: calWidth(10) }}>트레이너 등록하기</Body2Bold>
            </Row>
        </Row>
    );
};

export default AddTrainerButton;
