import React from 'react';
import { Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_EXIT, I_LOGO } from '../../types/images';

const SideBarHeader = ({ onClickSideBarButton }: any) => {
    return (
        <Row
            style={{
                width: calWidth(290),
                alignItems: 'center',
                justifyContent: 'space-between',
                // marginTop: calHeight(44),
            }}
        >
            <button
                style={{
                    width: 56,
                    height: 56,
                    border: 'none',
                    backgroundColor: colors.WHITE_200,
                }}
                onClick={onClickSideBarButton}
            >
                <img style={{ width: 56, height: 56 }} src={I_EXIT} />
            </button>

            <img style={{ width: 50, height: 24, marginRight: calWidth(24) }} src={I_LOGO} />
        </Row>
    );
};

export default SideBarHeader;
