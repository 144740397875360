import styled from 'styled-components';
import { Col, Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { I_GREEN_ALERT, I_RED_ALERT } from '../../types/images';
import { Body1Bold, Body3Regular, Subtitle1Bold } from '../../lib/font';

const ModalOverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000047;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2002;
`;

const ModalInner = styled.div`
    background: white;
    width: 600px;
    height: 300px;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

type Props = {
    title: string | null;
    content: string | null;
    callBack?: any;
    alertStateReset: any;
    isAlertTwoBtn: boolean;
    confirmBtnText: string | null;
};

const ModalAlertOneButton = ({ title, content, callBack, alertStateReset, isAlertTwoBtn, confirmBtnText }: Props) => {
    return (
        <ModalOverLay>
            <Col
                style={{
                    width: calWidth(358),
                    height: calHeight(204),
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    alignItems: 'center',
                }}
            >
                <Row style={{ width: calWidth(310), alignItems: 'center', marginTop: calHeight(26) }}>
                    <img
                        style={{ width: calWidth(24), height: calWidth(24) }}
                        src={isAlertTwoBtn ? I_RED_ALERT : I_GREEN_ALERT}
                    />
                    <Subtitle1Bold style={{ color: colors.BLUE_900, marginLeft: calWidth(8) }}>{title}</Subtitle1Bold>
                </Row>

                <Col style={{ width: calWidth(310), height: calHeight(80) }}>
                    <Body3Regular style={{ color: colors.WHITE_900, marginTop: calHeight(17), whiteSpace: 'pre-wrap' }}>
                        {content}
                    </Body3Regular>
                </Col>

                {isAlertTwoBtn ? (
                    <Row style={{ width: calWidth(310), justifyContent: 'space-between' }}>
                        <Row
                            style={{
                                width: calWidth(148),
                                height: calHeight(48),
                                borderRadius: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid rgba(61, 115, 221, 1)',
                            }}
                            onClick={alertStateReset}
                        >
                            <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>취소</Body1Bold>
                        </Row>
                        <Row
                            style={{
                                width: calWidth(148),
                                height: calHeight(48),
                                borderRadius: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid rgba(61, 115, 221, 1)',
                            }}
                            onClick={callBack}
                        >
                            <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>
                                {confirmBtnText ? confirmBtnText : '확인'}
                            </Body1Bold>
                        </Row>
                    </Row>
                ) : (
                    <Row
                        style={{
                            width: calWidth(148),
                            height: calHeight(48),
                            borderRadius: 48,
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid rgba(61, 115, 221, 1)',
                        }}
                        onClick={alertStateReset}
                    >
                        <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>확인</Body1Bold>
                    </Row>
                )}
            </Col>
        </ModalOverLay>
    );
};

export default ModalAlertOneButton;
