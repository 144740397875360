import { Col } from '../../lib/styled';
import { colors } from '../../lib/colors';
import styled from 'styled-components';
import { calWidth, clearLocalStorage, getItemWithExpiration } from '../../lib/utils';
import { useEffect, useState } from 'react';
import SideBarProfile from '../SideBar/SideBarProfile';
import SideBarHeader from '../SideBar/SideBarHeader';
import SideBarButtonList from '../SideBar/SideBarButtonList';
import AddTrainerButton from '../SideBar/AddTrainerButton';
import SideBarBottom from '../SideBar/SideBarBottom';
import { useNavigate } from 'react-router-dom';

let width = calWidth(290);

const SideBarWarp: any = styled.div`
    position: fixed;
    top: 0;
    z-index: 5;
    left: ${(props: any) => (props.visible === 'true' ? '0' : `-${width}`)};
    transition: left 0.3s ease; /* 트랜지션 효과 추가 */
`;

type Props = {
    isVisibleSidebar: boolean;
    onClickSideBarButton: () => void;
    userObject: any;
    centerObject: any;
    planObject: any;
};

const SideBar = ({ isVisibleSidebar, onClickSideBarButton, userObject, centerObject, planObject }: Props) => {
    const navigate = useNavigate();

    const onClickSignOut = () => {
        navigate('/signIn');
        clearLocalStorage();
    };

    return (
        <>
            <SideBarWarp visible={isVisibleSidebar ? 'true' : 'false'}>
                <Col
                    style={{
                        background: colors.WHITE_200,
                        width: calWidth(290),
                        minHeight: '100dvh',
                        overflow: 'hidden',
                        borderTopRightRadius: 50,
                        boxShadow: '4px 4px 12px 0px #0000001F',
                        position: 'fixed',
                        alignItems: 'center',
                        zIndex: 3,
                    }}
                >
                    <SideBarHeader onClickSideBarButton={onClickSideBarButton} />

                    <SideBarProfile userObject={userObject} centerObject={centerObject} planObject={planObject} />

                    <SideBarButtonList
                        onClickSideBarButton={onClickSideBarButton}
                        navigate={navigate}
                        centerObject={centerObject}
                    />

                    <AddTrainerButton navigate={navigate} onClickSideBarButton={onClickSideBarButton} />

                    <SideBarBottom
                        navigate={navigate}
                        onClickSideBarButton={onClickSideBarButton}
                        onClickSignOut={onClickSignOut}
                    />
                </Col>
            </SideBarWarp>
        </>
    );
};

export default SideBar;
