import React from 'react';
import { Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import dayjs from 'dayjs';

type Props = {
    selectedDay: Date;
};
const DailyScheduleShadow = ({ selectedDay }: Props) => {
    // 기기바다 뷰포트 height가 다달라서 임의로 정의
    // const innerHeight = window.innerHeight;
    // const HEIGHT_NUMBER = innerHeight < 700 ? 97.3 : innerHeight < 850 ? 97 : 96.9;
    // const colPosition = HEIGHT_NUMBER * parseInt(dayjs().format('H')) + parseInt(dayjs().format('m')) * (96 / 60);

    const colPosition = parseInt(dayjs().format('H')) * 96 + parseInt(dayjs().format('m')) * (96 / 60);

    return (
        <>
            {dayjs().format('YYYY-MM-DD') === dayjs(selectedDay).format('YYYY-MM-DD') && (
                <>
                    {/* Circle */}
                    <Row
                        style={{
                            position: 'absolute',
                            width: calWidth(8),
                            height: calWidth(8),
                            borderRadius: calWidth(8),
                            left: calWidth(37.3),
                            backgroundColor: colors.BLUE_700,
                            marginTop: colPosition - 3,
                            zIndex: 1,
                        }}
                    />
                    {/* Line */}
                    <Row
                        style={{
                            position: 'absolute',
                            width: calWidth(88 * 4 - 5),
                            marginTop: colPosition,
                            height: 1,
                            zIndex: 1,
                            left: calWidth(42.7),
                            backgroundColor: colors.BLUE_700,
                        }}
                    />
                    {/* Shadow */}
                    <Row
                        style={{
                            position: 'absolute',
                            width: calWidth(88 * 4 - 5),
                            height: colPosition,
                            opacity: 0.36,
                            left: calWidth(42.1),
                            backgroundColor: colors.WHITE_600,
                        }}
                    />
                </>
            )}
        </>
    );
};

export default DailyScheduleShadow;
