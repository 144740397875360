import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 일간 스케쥴 조회 API
 */
const getDailyScheduleAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/daily-schedules/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 주간 스케쥴 조회 API
 */
const getWeeklyScheduleAPI = async (centerId: string, date: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/weekly-schedules/${centerId}?date=${date}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};
