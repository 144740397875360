import axios, { AxiosResponse } from 'axios';

export /**
 * @method post
 * @brief 관리자 로그인
 */
const requestSignInAPI = async (adminId: string, password: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/v1/admins/signin`,
            headers: {},
            data: {
                adminId,
                password,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 418:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
