import React, { CSSProperties } from 'react';

import { Subtitle1Bold } from '../../lib/font';
import styled from 'styled-components';
type Props = {
    text: string;
    type?: any;
    style?: CSSProperties;
    onClick?: any;
    disabled?: boolean;
};

const NormalButtonWrap = styled.button`
    width: 342px;
    height: 56px;
    border-radius: 48px;
    background-color: #3d73dd;
    color: #fdfeff;
    border: none;
`;

/**
 * 180 * 60 크기의 노말버튼
 */
const NormalButton = ({ text, type = 'button', style, onClick, disabled }: Props) => {
    return (
        <NormalButtonWrap type={type} disabled={disabled} style={style} onClick={onClick}>
            <Subtitle1Bold>{text}</Subtitle1Bold>
        </NormalButtonWrap>
    );
};

export default NormalButton;
