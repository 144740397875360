import React from 'react';
import { Col, Row } from '../../lib/styled';
import { Body1Bold, Body2Regular, Body3Bold, Body3Light, Head3 } from '../../lib/font';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';

const TrainerRegisterStep2 = ({ digits, handleChange, inputRefs, isErrorToast }: any) => {
    return (
        <Col>
            <Col style={{ marginLeft: calWidth(16), marginTop: calHeight(32) }}>
                <Body2Regular style={{ color: colors.WHITE_800 }}>트레이너 코드를 입력해주세요</Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>
                    {'트레이너 코드는 [라포 모바일 > 트레이너 계정 > My 코드번호]'}
                </Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>를 통해 공유받을 수 있습니다</Body2Regular>
            </Col>

            <Row style={{ justifyContent: 'center', marginTop: calHeight(48) }}>
                <Col
                    style={{
                        width: 358,
                        height: 156,
                        borderRadius: 8,
                        backgroundColor: isErrorToast ? colors.ERROR2 : colors.WHITE_500,
                        // alignItems: 'center',
                    }}
                >
                    <Row
                        style={{
                            width: 294,
                            marginLeft: 32,
                            justifyContent: 'space-between',
                            marginTop: calHeight(24),
                        }}
                    >
                        <Body1Bold style={{ color: colors.BLUE_900 }}>강사 코드넘버</Body1Bold>
                        {/* <Row
                            style={{
                                width: 96,
                                height: 24,
                                borderRadius: 24,
                                backgroundColor: colors.PRIMARY_BLUE,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Body3Bold style={{ color: colors.WHITE_50 }}>코드 요청하기</Body3Bold>
                        </Row> */}
                    </Row>
                    <Row
                        style={{
                            // width: 294,
                            marginLeft: 32,
                            marginRight: 32,
                            justifyContent: 'space-between',
                            marginTop: calHeight(24),
                        }}
                    >
                        {digits.map((digit: any, index: number) => (
                            <input
                                key={index}
                                ref={inputRefs[index]}
                                type={index === 0 ? 'text' : 'number'}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value.slice(0, 1))}
                                maxLength={1}
                                autoFocus={index === 0}
                                style={{
                                    width: 50,
                                    height: 50,
                                    backgroundColor: colors.WHITE_50,
                                    borderRadius: 4,
                                    boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                                    fontSize: 20,
                                    fontWeight: 700,
                                    textAlign: 'center',
                                    border: '1px solid #3D73DD',
                                }}
                            />
                        ))}
                    </Row>
                </Col>
            </Row>
            <Body3Light style={{ color: colors.WHITE_900, marginLeft: calWidth(16), marginTop: calHeight(8) }}>
                *공유받은 강사의 코드넘버를 입력하시면 자동으로 연결 됩니다!
            </Body3Light>
        </Col>
    );
};

export default TrainerRegisterStep2;
