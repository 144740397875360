import React, { useRef, useState } from 'react';
import { Col, Row } from '../lib/styled';
import { calHeight, calWidth, getItemWithExpiration } from '../lib/utils';
import { colors } from '../lib/colors';
import TrainerRegisterHeader from '../components/TrainerRegister/TrainerRegisterHeader';
import { useNavigate } from 'react-router-dom';
import TrainerRegisterStep1 from '../components/TrainerRegister/TrainerRegisterStep1';
import TrainerRegisterStep2 from '../components/TrainerRegister/TrainerRegisterStep2';
import TrainerRegisterStep3 from '../components/TrainerRegister/TrainerRegisterStep3';
import { useMutation, useQuery } from '@tanstack/react-query';
import { connectingTrainerAPI, getAddTrainerInfoAPI } from '../api/trainer';
import BottomButton from '../components/TrainerRegister/BottomButton';
import { Body1Regular } from '../lib/font';
import { I_GREEN_ERROR, I_RED_ERROR } from '../types/images';

const TrainerRegisterPage = () => {
    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [centerObject, setCenterObject] = useState(getItemWithExpiration('centerObject'));
    const [step, setStep] = useState<number>(1);
    const [digits, setDigits] = useState(['', '', '', '']);
    const inputRefs: any = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [isToast, setIsToast] = useState<boolean>(false);
    const [isErrorToast, setIsErrorToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>('');

    // 트레이너 조회 플래그
    const [getTrainerInfoFlag, setGetTrainerInfoFlag] = useState<boolean>(false);
    // 트레이너 인포
    const [coachId, setCoachId] = useState<string>('');
    const [coachInviteCode, setCoachInviteCode] = useState<string>('');
    const [coachName, setCoachName] = useState<string>('');
    const [coachProfileImageUrl, setCoachProfileImageUrl] = useState<string>('');

    const handleChange = (index: any, value: any) => {
        let sanitizedValue;

        if (index === 0) {
            sanitizedValue = value.replace(/[^A-Za-z]/g, '').toUpperCase();
        } else {
            sanitizedValue = value.replace(/[^0-9]/g, '');
        }

        const newDigits = [...digits];
        newDigits[index] = sanitizedValue;
        setDigits(newDigits);

        if (index < inputRefs.length - 1 && sanitizedValue !== '') {
            inputRefs[index + 1].current.focus();
        }
    };

    const onClickNextStep = () => {
        if (step === 3) {
            connectingTrainer.mutate();
            return;
        } else {
            if (step === 1) {
                setStep(2);
            }
            if (step === 2) {
                setGetTrainerInfoFlag(true);
            }
        }
    };

    const onClickPrevStep = () => {
        if (step === 1) {
            return;
        } else {
            setStep(step - 1);
            setCoachId('');
            setCoachInviteCode('');
            setCoachName('');
            setCoachProfileImageUrl('');
            setDigits(['', '', '', '']);
            setGetTrainerInfoFlag(false);
        }
    };

    const onClickExit = () => {
        navigate(-1);
    };

    const ToastFunction = (msg: string) => {
        setToastMsg(msg);
        setIsToast(true);
        setTimeout(() => {
            setIsToast(false);
            setIsErrorToast(false);
        }, 1500);
    };

    const onClickClipBoard = async (type: string) => {
        try {
            await navigator.clipboard.writeText('https://rappo.page.link/4Yif').then(() => {
                type === 'ios'
                    ? ToastFunction('‘라포’ 앱스토어 링크가 복사되었습니다!')
                    : ToastFunction('‘라포’ 구글스토어 링크가 복사되었습니다!');
            });
        } catch (error) {
            alert('클립보드 복사실패');
        }
    };

    //  트레이너 조회 API : (GET)
    const getAddTrainerInfo = useQuery(
        ['getAddTrainerInfoAPI'],
        async () =>
            await getAddTrainerInfoAPI(
                centerObject?.centerId,
                digits[0] + digits[1] + digits[2] + digits[3],
                authorization
            ),
        {
            onSuccess: (res) => {
                setGetTrainerInfoFlag(false);

                if (res.status === 200) {
                    const coach = res.data;
                    setCoachId(coach.coachId);
                    setCoachInviteCode(coach.coachInviteCode);
                    setCoachName(coach.coachName);
                    setCoachProfileImageUrl(coach.coachProfileImageUrl);

                    setStep(3);
                }
                if (res.status === 404) {
                    setGetTrainerInfoFlag(false);
                    setIsErrorToast(true);
                    ToastFunction('존재하지 않는 코드 넘버 입니다');
                }
                if (res.status === 409) {
                    setGetTrainerInfoFlag(false);
                    setIsErrorToast(true);
                    ToastFunction('이미 등록된 트레이너 입니다');
                }
            },
            onError: (err) => {
                setGetTrainerInfoFlag(false);
                alert('알수 없는 오류입니다');
                console.log(err);
            },
            enabled: getTrainerInfoFlag,
            staleTime: 0,
        }
    );

    // 트레이너 연결 (PATCH)
    const connectingTrainer = useMutation({
        mutationFn: async () => await connectingTrainerAPI(centerObject?.centerId, coachId, authorization),
        onSuccess: (res) => {
            console.log(res);
            if (res.status === 200) {
                navigate(-1);
                alert('등록 완료');
                // createAlert(
                //     '등록 완료',
                //     `트레이너 등록이 완료되었습니다\n트레이너의 상세 정보는 '트레이너 정보'에서 상세 입력할 수 있습니다`
                // );
            }
            if (res.status === 404) {
                alert('트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요');
                // createAlert('', '트레이너를 찾을 수 없습니다!\n 코드를 다시 한번 확인해주세요');
            }
            if (res.status === 409) {
                alert('이미 등록된 트레이너 입니다');
                // createAlert('', '이미 등록된 트레이너 입니다');
            }
        },
    });

    return (
        <Col style={{ backgroundColor: colors.WHITE_200, height: '100dvh' }}>
            <TrainerRegisterHeader step={step} onClickExit={onClickExit} onClickPrevStep={onClickPrevStep} />
            {step === 1 && <TrainerRegisterStep1 onClickClipBoard={onClickClipBoard} />}
            {step === 2 && (
                <TrainerRegisterStep2
                    digits={digits}
                    handleChange={handleChange}
                    inputRefs={inputRefs}
                    isErrorToast={isErrorToast}
                />
            )}
            {step === 3 && (
                <TrainerRegisterStep3
                    coachName={coachName}
                    coachInviteCode={coachInviteCode}
                    coachProfileImageUrl={coachProfileImageUrl}
                />
            )}

            <BottomButton onClickNextStep={onClickNextStep} step={step} />

            {isToast && toastMsg && (
                <Row style={{ width: '100vw', justifyContent: 'center' }}>
                    <Row
                        style={{
                            width: calWidth(358),
                            height: calHeight(56),
                            borderRadius: 48,
                            backgroundColor: colors.WHITE_900,
                            position: 'absolute',
                            bottom: 100,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={isErrorToast ? I_RED_ERROR : I_GREEN_ERROR}
                            style={{ width: calWidth(24), height: calWidth(24), opacity: 0.8, marginRight: 8 }}
                        />
                        <Body1Regular style={{ color: colors.WHITE_50 }}>{toastMsg}</Body1Regular>
                    </Row>
                </Row>
            )}
        </Col>
    );
};

export default TrainerRegisterPage;
