import React from 'react';
import { Col, Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { Body1Bold, Body2Bold, Body2Regular } from '../../../lib/font';

const CommunicationDetailTop = ({ detailObject }: any) => {
    return (
        <>
            <Row style={{ width: '100vw', justifyContent: 'center' }}>
                <Col style={{ width: calWidth(374), height: calHeight(116), marginTop: calHeight(16) }}>
                    <Row
                        style={{
                            width: calWidth(88),
                            height: calHeight(40),
                            borderRadius: 8,
                            backgroundColor: detailObject?.status === 'PENDING' ? colors.ERROR : colors.SUCCESS,
                            position: 'absolute',
                            marginLeft: calWidth(16),
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Body1Bold style={{ color: colors.WHITE_50 }}>
                            {detailObject?.status === 'PENDING' ? '답변 대기' : '답변 완료'}
                        </Body1Bold>
                    </Row>
                    <Col
                        style={{
                            width: calWidth(374),
                            height: calHeight(108),
                            backgroundColor: colors.WHITE_200,
                            borderRadius: 8,
                            boxShadow: '1px 1px 8px 0px rgba(43, 82, 157, 0.18)',
                            marginTop: calHeight(8),
                        }}
                    >
                        <Row style={{ marginTop: calHeight(40), marginLeft: calWidth(24), alignItems: 'center' }}>
                            <Body2Bold style={{ color: colors.BLUE_700 }}>문의 유형</Body2Bold>
                            <Body2Regular style={{ marginLeft: calWidth(8), color: colors.WHITE_900 }}>
                                {detailObject?.category === 'PTFEEDBACK'
                                    ? 'PT 피드백'
                                    : detailObject?.category === 'FACILITY'
                                    ? '시설문의'
                                    : detailObject?.category === 'COUNSEL'
                                    ? '상담문의'
                                    : '기타'}
                            </Body2Regular>
                        </Row>

                        <Row style={{ marginTop: calHeight(8), marginLeft: calWidth(24), alignItems: 'center' }}>
                            <Body2Bold style={{ color: colors.BLUE_700 }}>회원 이름</Body2Bold>
                            <Body2Regular style={{ marginLeft: calWidth(8), color: colors.WHITE_900 }}>
                                {detailObject?.memberName}
                            </Body2Regular>
                            <div
                                style={{
                                    width: 2,
                                    height: 2,
                                    borderRadius: 1,
                                    backgroundColor: colors.BLUE_900,
                                    marginLeft: calWidth(16),
                                    marginRight: calWidth(16),
                                }}
                            />
                            <Body2Bold style={{ color: colors.BLUE_700 }}>강사 이름</Body2Bold>
                            <Body2Regular style={{ marginLeft: calWidth(8), color: colors.WHITE_900 }}>
                                {detailObject?.coachName}
                            </Body2Regular>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default CommunicationDetailTop;
