import React from 'react';
import { Col, Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_EMPTY_PROFILE } from '../../../types/images';
import { Body2Bold, Body2Regular, CaptionRegular } from '../../../lib/font';
import dayjs from 'dayjs';

const CommunicationDetailBody = ({
    detailObject,
    isEditMode,
    textareaRef,
    editText,
    handleEditChange,
    onClickImage,
}: any) => {
    return (
        <>
            {detailObject &&
                detailObject.contents.map((item: any, index: number) => {
                    return (
                        <Row key={'mobileCommContent' + index} style={{ width: '100vw', justifyContent: 'center' }}>
                            <Col
                                style={{
                                    width: calWidth(374),
                                    // height: calHeight(306),
                                    borderRadius: 8,
                                    backgroundColor: colors.WHITE_200,
                                    boxShadow: '1px 1px 8px 0px rgba(43, 82, 157, 0.18)',
                                    marginTop: calHeight(16),
                                }}
                            >
                                <Row
                                    style={{ alignItems: 'center', marginLeft: calWidth(16), marginTop: calHeight(16) }}
                                >
                                    <img src={I_EMPTY_PROFILE} style={{ width: calWidth(36), height: calWidth(36) }} />
                                    <Col style={{ marginLeft: calWidth(3) }}>
                                        <Body2Bold
                                            style={{ color: item.role === 'admin' ? colors.SUCCESS : colors.BLUE_700 }}
                                        >
                                            {item.role === 'admin' ? '센터 답변' : '질문'}
                                        </Body2Bold>
                                        <CaptionRegular style={{ color: colors.WHITE_700, marginTop: calHeight(2) }}>
                                            {dayjs(item?.updatedTime).format('YYYY/MM/DD(ddd)/A HH:mm')}
                                        </CaptionRegular>
                                    </Col>
                                </Row>

                                <Row
                                    style={{
                                        width: calWidth(374),
                                        marginTop: calHeight(8),
                                        justifyContent: 'center',
                                        marginBottom: calHeight(16),
                                    }}
                                >
                                    {isEditMode &&
                                    detailObject.contents.length !== 1 &&
                                    detailObject.contents.length - 1 === index ? (
                                        <Row
                                            style={{
                                                width: calWidth(342),
                                                marginTop: calHeight(8),
                                                justifyContent: 'center',
                                                marginBottom: calHeight(16),
                                                paddingLeft: calWidth(16),
                                                paddingRight: calWidth(16),
                                            }}
                                        >
                                            <textarea
                                                ref={textareaRef}
                                                value={editText}
                                                style={{
                                                    width: calWidth(342),
                                                    // height: calHeight(230),
                                                    borderRadius: 4,
                                                    border: '1px solid rgba(165, 171, 181, 1)',
                                                    boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: calWidth(16),
                                                }}
                                                onChange={handleEditChange}
                                                placeholder="답변을 작성해주세요"
                                            />
                                        </Row>
                                    ) : (
                                        <Col
                                            style={{
                                                width: calWidth(342),
                                                // height: calHeight(230),
                                                borderRadius: 4,
                                                border:
                                                    item.role === 'admin'
                                                        ? '1px solid rgba(0, 167, 147, 1)'
                                                        : '1px solid rgba(43, 82, 157, 1)',
                                                boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingTop: calHeight(16),
                                                paddingBottom: calHeight(16),
                                            }}
                                        >
                                            <Body2Regular style={{ width: calWidth(310) }}>{item.content}</Body2Regular>
                                        </Col>
                                    )}
                                </Row>

                                <Row style={{ marginLeft: calWidth(16), marginBottom: calHeight(16) }}>
                                    {item.imageUrls &&
                                        item.imageUrls.map((image: any, imageIndex: number) => {
                                            return (
                                                <Row
                                                    onClick={() => onClickImage(item.imageUrls)}
                                                    key={'mobileImage' + imageIndex + index}
                                                >
                                                    <img
                                                        src={image}
                                                        style={{
                                                            width: calWidth(56),
                                                            height: calWidth(56),
                                                            marginRight: calWidth(8),
                                                        }}
                                                    />
                                                </Row>
                                            );
                                        })}
                                </Row>
                            </Col>
                        </Row>
                    );
                })}
        </>
    );
};

export default CommunicationDetailBody;
