import React from 'react';
import { CaptionBold, OverlineLight } from '../../lib/font';
import dayjs from 'dayjs';
import { colors } from '../../lib/colors';
import { calWidth } from '../../lib/utils';
import { Col } from '../../lib/styled';

const DailyScheduleBox = ({ dailyCurrentData }: any) => {
    return (
        <>
            {dailyCurrentData &&
                dailyCurrentData.map((parentItem: any, parentIndex: number) => {
                    return parentItem.schedules.map((childItem: any, childIndex: number) => {
                        const tenMinuteHeight = 1.6;
                        const durationTransTemMinute = childItem.duration * tenMinuteHeight - 2;

                        // 절대적 세로 위치찾기
                        const innerHeight = window.innerHeight > 700 ? 0 : 1.02;
                        // const colPosition =
                        //     parseInt(dayjs(childItem.startTime).format('H')) * 96 +
                        //     parseInt(dayjs(childItem.startTime).format('m')) * (96 / 60) +
                        //     parseInt(dayjs(childItem.startTime).format('H')) * innerHeight;

                        const colPosition =
                            1 +
                            parseInt(dayjs(childItem.startTime).format('H')) * 96 +
                            parseInt(dayjs(childItem.startTime).format('m')) * (96 / 60);

                        const isToday =
                            dayjs(childItem?.startTime).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD');
                        const isSameHour = dayjs(childItem?.startTime).format('HH') === dayjs().format('HH');
                        const isBetweenTime =
                            parseInt(dayjs(childItem?.startTime).format('HH')) <= parseInt(dayjs().format('HH')) &&
                            parseInt(dayjs().format('HH')) <= parseInt(dayjs(childItem?.endTime).format('HH'));

                        return childItem?.type === 'emptySchedule' ? (
                            <Col
                                key={'empty' + childIndex}
                                style={{
                                    position: 'absolute',
                                    width: calWidth(78),
                                    height: durationTransTemMinute,
                                    backgroundColor: colors.WHITE_500,
                                    marginLeft: calWidth(45.7 + 87 * parentIndex),
                                    marginTop: colPosition,
                                    boxShadow: '4px 4px 8px 1px #213E7B29 inset',

                                    borderRadius: 8,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            />
                        ) : (
                            //
                            <Col
                                key={'scheduleBox' + childIndex}
                                style={{
                                    position: 'absolute',
                                    width: calWidth(78),
                                    height:
                                        childItem.type === 'schedule' && isToday && isSameHour
                                            ? durationTransTemMinute - 2
                                            : durationTransTemMinute,
                                    backgroundColor:
                                        childItem?.type === 'schedule'
                                            ? colors.WHITE_50
                                            : childItem.type === 'onetimeSchedule'
                                            ? colors.BLUE_100
                                            : colors.ERROR,
                                    marginLeft: calWidth(45.7 + 87 * parentIndex),
                                    border:
                                        childItem.type === 'schedule' && isToday && isSameHour
                                            ? '1px solid #7DA1E8'
                                            : '',
                                    marginTop: colPosition,
                                    borderRadius: 8,
                                    boxShadow: '1px 1px 8px 0px #2B529D2E',

                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CaptionBold style={{ color: colors.BLUE_900 }}>
                                    {childItem.type === 'schedule' ? childItem?.alias : childItem?.name}
                                </CaptionBold>
                                {childItem?.duration > 15 && (
                                    <OverlineLight
                                        style={{
                                            color: colors.WHITE_900,
                                            marginTop: childItem?.duration > 20 ? 3 : 0,
                                        }}
                                    >
                                        {dayjs(childItem?.startTime).format('AHH:mm~')}
                                        {dayjs(childItem?.endTime).format('HH:mm')}
                                    </OverlineLight>
                                )}

                                {childItem?.duration > 20 && childItem?.type === 'schedule' && (
                                    <OverlineLight
                                        style={{
                                            color: colors.WHITE_900,
                                            marginTop: childItem?.duration > 20 ? 3 : 0,
                                        }}
                                    >
                                        {childItem.currentSession ? childItem.currentSession : 0}/
                                        {childItem.totalSession ? childItem.totalSession : 0}회
                                    </OverlineLight>
                                )}
                            </Col>
                        );
                    });
                })}

            {/* 절대값으로 그리는 블럭 */}
            {/* <Col
                style={{
                    position: 'absolute',
                    width: calWidth(86),
                    height: (96 / 6) * 3,

                    backgroundColor: colors.ERROR,
                    borderRadius: 8,
                    marginLeft: calWidth(41.4 + 87 * 0),
                    marginTop: 96 * 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CaptionBold>오회원</CaptionBold>
                <CaptionLight>AM11:00~11:20</CaptionLight>
            </Col> */}
        </>
    );
};

export default DailyScheduleBox;
