import React, { Fragment } from 'react';
import OneTimeScheduleBox from './OneTimeScheduleBox';
import ScheduleBox from './ScheduleBox';
import { Col, Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import dayjs from 'dayjs';
import { Body3Bold, Body3Regular } from '../../../lib/font';

const ScheduleLayout = ({ isPrevWeek, isAfterWeek, gridData, lengthArr, isThisWeek }: any) => {
    const HEIGHT_NUMBER = window.innerHeight < 700 ? 60 : window.innerHeight < 840 ? 52 : 46;
    return (
        <Col>
            {gridData &&
                gridData.map((row: any, rowIndex: number) => {
                    return (
                        <Row style={{ marginTop: rowIndex === 0 ? calHeight(HEIGHT_NUMBER) : 0 }} key={rowIndex}>
                            {row.map((cell: any, cellIndex: any) => {
                                return cellIndex === 0 ? (
                                    <Col
                                        key={'weeklyBox' + cellIndex}
                                        style={{
                                            minWidth: calWidth(42),
                                            height: lengthArr
                                                ? Math.max(...lengthArr[rowIndex]) === 0
                                                    ? calHeight(84)
                                                    : calHeight((Math.max(...lengthArr[rowIndex]) - 1) * 84 + 84)
                                                : calHeight(84),
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: colors.WHITE_300,
                                        }}
                                    >
                                        {parseInt(dayjs().format('H')) === rowIndex ? (
                                            <Body3Bold style={{ color: colors.BLUE_900 }}>{cell.content}</Body3Bold>
                                        ) : parseInt(dayjs().format('H')) + 1 === rowIndex ? (
                                            <Body3Regular style={{ color: colors.BLUE_700 }}>
                                                {cell.content}
                                            </Body3Regular>
                                        ) : (
                                            <Body3Regular style={{ color: colors.WHITE_900 }}>
                                                {cell.content}
                                            </Body3Regular>
                                        )}
                                    </Col>
                                ) : (
                                    <Col
                                        key={'weeklyBox' + cellIndex}
                                        style={{
                                            minWidth: calWidth(116),
                                            height: lengthArr
                                                ? Math.max(...lengthArr[rowIndex]) === 0
                                                    ? calHeight(84)
                                                    : calHeight((Math.max(...lengthArr[rowIndex]) - 1) * 84 + 84)
                                                : calHeight(84),
                                            border: '0.1px solid #C3D4F4',
                                            backgroundColor:
                                                isPrevWeek && !isAfterWeek
                                                    ? 'rgb(227, 233, 245)'
                                                    : dayjs(cell.dateFormat).isBefore(dayjs().startOf('day'))
                                                    ? 'rgb(227, 233, 245)'
                                                    : dayjs().format('YYYY-MM-DD') ===
                                                          dayjs(cell.dateFormat).format('YYYY-MM-DD') &&
                                                      parseInt(dayjs().format('H')) > rowIndex
                                                    ? 'rgb(227, 233, 245)'
                                                    : colors.WHITE_300,
                                            alignItems: 'center',
                                        }}
                                    >
                                        {cell.schedules.map((scheduleItem: any, scheduleIndex: number) => {
                                            return scheduleItem.type === 'onetimeSchedule' ? (
                                                <Fragment key={'noReason' + scheduleIndex}>
                                                    <OneTimeScheduleBox
                                                        scheduleIndex={scheduleIndex}
                                                        scheduleItem={scheduleItem}
                                                    />
                                                </Fragment>
                                            ) : (
                                                <Fragment key={'noReason' + scheduleIndex}>
                                                    <ScheduleBox
                                                        scheduleIndex={scheduleIndex}
                                                        scheduleItem={scheduleItem}
                                                    />
                                                </Fragment>
                                            );
                                        })}
                                    </Col>
                                );
                            })}
                        </Row>
                    );
                })}
        </Col>
    );
};

export default ScheduleLayout;
