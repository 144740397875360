import React, { useState } from 'react';
import Header from '../components/global/Header';
import { Col } from '../lib/styled';
import { calHeight, calWidth, getItemWithExpiration } from '../lib/utils';
import TrainerManageTop from '../components/TrainerManage/TrainerManageTop';
import TrainerManageBottom from '../components/TrainerManage/TrainerManageBottom';
import { useQuery } from '@tanstack/react-query';
import { getTrainerListAPI } from '../api/trainer';

const TrainerManagePage = ({ onClickSideBarButton }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [centerObject, setCenterObject] = useState(getItemWithExpiration('centerObject'));
    // 초기값 설정
    const [sortType, setSortType] = useState<any>({ value: 'registrationasc', label: '등록 순' });
    const [currentTrainerList, setCurrentTrainerList] = useState<any>(null);
    const [deletedTrainerList, setDeletedTrainerList] = useState<any>(null);

    //  트레이너 리스트 조회 API : (GET)
    const getTrainerList = useQuery(
        ['getTrainerListAPI', sortType.value],
        async () => await getTrainerListAPI(centerObject.centerId, sortType.value, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const registeredTrainer = res.data.registeredTrainer;
                    const deletedTrainer = res.data.deletedTrainer;

                    setCurrentTrainerList(registeredTrainer);
                    setDeletedTrainerList(deletedTrainer);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    return (
        <Col>
            <Header onClick={onClickSideBarButton} headerText={'트레이너 관리'} isFixed={true} />

            <Col style={{ marginTop: calHeight(56), paddingLeft: calWidth(16) }}>
                <TrainerManageTop currentTrainerList={currentTrainerList} />
                <TrainerManageBottom deletedTrainerList={deletedTrainerList} />
            </Col>
        </Col>
    );
};

export default TrainerManagePage;
