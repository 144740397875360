import React from 'react';
import { Col, Row } from '../../lib/styled';
import { calHeight, calHeightToPixel, calWidth } from '../../lib/utils';
import { I_HAMBURGER } from '../../types/images';
import { Subtitle1Bold } from '../../lib/font';
import { colors } from '../../lib/colors';

type Props = {
    onClick: () => void;
    headerText: string;
    isFixed: boolean;
};
const Header = ({ onClick, headerText, isFixed }: Props) => {
    return (
        <Row
            style={{
                width: '100vw',
                height: calHeight(56),
                alignItems: 'center',
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                backgroundColor: colors.WHITE_200,
                position: isFixed ? 'fixed' : 'relative',
                zIndex: 3,
            }}
        >
            <Col
                onClick={onClick}
                style={{
                    width: calWidth(56),
                    height: calHeight(56),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src={I_HAMBURGER} style={{ width: 18, height: 16 }} />
            </Col>

            <Subtitle1Bold style={{ color: colors.BLUE_900, marginLeft: calWidth(92) }}>{headerText}</Subtitle1Bold>
        </Row>
    );
};

export default Header;
