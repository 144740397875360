import axios, { AxiosResponse } from 'axios';

export /**
 * @method get
 * @brief 트레이너 등록창에서 조회 API
 */
const getAddTrainerInfoAPI = async (centerId: string, inviteCode: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/check-coach/${centerId}/${inviteCode}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method get
 * @brief 트레이너 관리 / 트레이너 리스트 조회 API
 */
const getTrainerListAPI = async (centerId: string, sort: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/coach-list/${centerId}?sort=${sort}`,
            headers: { authorization: accessToken },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류.');
                return { status: error.response.status };
        }
    }
};

export /**
 * @method patch
 * @brief 트레이너 연결 API
 */
const connectingTrainerAPI = async (centerId: string, coachId: string, accessToken: string): Promise<any> => {
    let response = {} as AxiosResponse<any, any>;
    try {
        response = await axios({
            method: 'patch',
            url: `${process.env.REACT_APP_SERVER}/v1/centers/connect-coach/${centerId}`,
            headers: { authorization: accessToken },
            data: {
                coachId,
            },
        });

        if (response.status === 200) {
            return response;
        }
    } catch (error: any) {
        // Error 😨
        switch (error.response.status) {
            case 400:
                return { status: error.response.status };
            case 401:
                return { status: error.response.status };
            case 403:
                return { status: error.response.status };
            case 404:
                return { status: error.response.status };
            case 406:
                return { status: error.response.status };
            case 409:
                return { status: error.response.status };
            case 500:
                alert('서버 내부 오류');
                return { status: error.response.status };
        }
    }
};
