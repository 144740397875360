import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Body2Bold, Body2Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { I_DROP_DOWN, I_DROP_UP } from '../../types/images';

// 페이드인 애니메이션 정의
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const InputButton = styled.button`
    width: 112px;
    height: 36px;
    background-color: rgba(245, 249, 255, 1);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    box-shadow: 1px 1px 8px 0px #2b529d2e;
    display: flex;
    align-items: center;
    padding-left: 18px;
    position: 'relative';
    z-index: 2;
    border: none;
`;

const InputArrowButton = styled.button`
    width: 36px;
    height: 36px;
    background-color: rgba(245, 249, 255, 1);
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 1px 1px 8px 0px #2b529d2e;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border: none;
`;

const DropDownWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 148px;
    background-color: rgba(245, 249, 255, 1);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.12);
    justify-content: end;
    position: absolute;
    top: 22px;
    padding-top: 18px;
    padding-bottom: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    animation: ${fadeIn} 0.5s ease-in-out;
    z-index: 1;
`;

const DropDownItem = styled.div`
    width: 129px;
    height: 36px;
    background: rgba(245, 249, 255, 0.6);

    box-shadow: 0px 0px 6px 0px rgba(43, 82, 157, 0.18);

    color: rgba(98, 101, 107, 1);
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    /* font-size: 14px;
    padding-left: 18px;
    font-weight: 400px; */
`;

interface I_Props {
    toggleSortDropDown: () => void;
    sortType: any;
    isOpenSort: boolean;
    sortOptions: any;
    handleSortSelect: (gender: string) => void;
    type?: any;
}

const NormalDropdown = ({
    toggleSortDropDown,
    sortType,
    isOpenSort,
    sortOptions,
    handleSortSelect,
    type = 'button',
}: I_Props) => {
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex' }}>
                <InputButton onClick={toggleSortDropDown} type={type}>
                    <Body2Bold style={{ color: colors.BLUE_900 }}>{sortType.label}</Body2Bold>
                </InputButton>
                <InputArrowButton type={type} onClick={toggleSortDropDown}>
                    <img src={isOpenSort ? I_DROP_UP : I_DROP_DOWN} style={{ width: 14 }} />
                </InputArrowButton>
            </div>
            {isOpenSort && (
                <DropDownWrap>
                    {sortOptions.map((option: any, index: number) => (
                        <DropDownItem key={option.value} onClick={() => handleSortSelect(option)}>
                            <Body2Regular>{option.label}</Body2Regular>
                        </DropDownItem>
                    ))}
                </DropDownWrap>
            )}
        </div>
    );
};

export default NormalDropdown;
