import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getItemWithExpiration } from './utils';

const PrivateRoutes = ({ setIsVisiblePayPending }: any) => {
    const navigate = useNavigate();
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [planObject, setPlanObject] = useState(getItemWithExpiration('planObject'));
    const location = useLocation();

    // usableStatus 가 needPlan이면 만료페이지로 던진다. 하지만 expired가 url에 포함되잇으면 네비게이팅멈춘다
    useEffect(() => {
        if (planObject) {
            planObject.usableStatus === 'needPlanToFree' &&
                !location.pathname.includes('expired') &&
                navigate('/expired', { replace: true });
            planObject.usableStatus === 'needPlanToCharge' &&
                !location.pathname.includes('expired') &&
                navigate('/expired', { replace: true });

            if (
                (!planObject.confirmUsableStatus && planObject.usableStatus === 'trialPaymentPending') ||
                planObject.usableStatus === 'planPaymentPending'
            ) {
                setIsVisiblePayPending(true);
            }
        }
    }, [planObject]);

    return authorization ? <Outlet /> : <Navigate to="/signIn" />;
};

export default PrivateRoutes;
