import React from 'react';
import { Body2Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import {
    I_LEFT_GRAY_TRIANGLE,
    I_LEFT_GRAY_TWO_TRIANGLE,
    I_LEFT_PRIMARY_BTN,
    I_LEFT_PRIMARY_TWO_TRIANGLE,
    I_RIGHT_GRAY_TRIANGLE,
    I_RIGHT_GRAY_TWO_TRIANGLE,
    I_RIGHT_PRIMARY_BTN,
    I_RIGHT_PRIMARY_TWO_TRIANGLE,
} from '../../../types/images';
import { Row } from '../../../lib/styled';
import { calHeight } from '../../../lib/utils';

const CommunicationPaging = ({
    filterList,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    onClickStartPage,
    onClickLastPage,
}: any) => {
    const totalPages = filterList && Math.ceil(filterList.length / itemsPerPage);
    const pagesToShow = totalPages && Math.min(5, totalPages); // 최대 5개의 페이지만 표시
    const renderPages = () => {
        const pages = [];
        const startPage = Math.floor((currentPage - 1) / 5) * 5 + 1; // 페이지 숫자 범위 시작

        for (let i = startPage; i < startPage + pagesToShow; i++) {
            if (i > totalPages) break; // 페이지 수를 초과하면 중단

            pages.push(
                <button
                    style={{
                        width: 9,
                        height: 22,
                        marginLeft: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none',
                        backgroundColor: colors.WHITE_300,
                    }}
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    disabled={currentPage === i}
                >
                    <Body2Bold
                        style={{
                            color: currentPage === i ? colors.PRIMARY_BLUE : colors.WHITE_700,
                        }}
                    >
                        {i}
                    </Body2Bold>
                </button>
            );
        }

        if (pages.length === 5) {
            pages.push(
                <Body2Bold
                    key={'...'}
                    style={{
                        width: 9,
                        height: 22,
                        marginLeft: 16,
                        color: colors.WHITE_700,
                    }}
                >
                    {'...'}
                </Body2Bold>
            );
        }

        return pages;
    };

    return (
        <Row
            style={{
                width: '100vw',
                justifyContent: 'center',
                marginTop: calHeight(24),
                marginBottom: calHeight(33),
                alignItems: 'center',
            }}
        >
            <button
                style={{
                    width: 24,
                    height: 24,
                    marginRight: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    backgroundColor: colors.WHITE_300,
                }}
                onClick={onClickStartPage}
                disabled={currentPage === 1}
            >
                <img
                    style={{ width: 24, height: 24 }}
                    src={currentPage === 1 ? I_LEFT_GRAY_TWO_TRIANGLE : I_LEFT_PRIMARY_TWO_TRIANGLE}
                />
            </button>
            <button
                style={{
                    width: 24,
                    height: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    backgroundColor: colors.WHITE_300,
                }}
                onClick={onClickPrevPage}
                disabled={currentPage === 1}
            >
                <img
                    style={{ width: 24, height: 24 }}
                    src={currentPage === 1 ? I_LEFT_GRAY_TRIANGLE : I_LEFT_PRIMARY_BTN}
                />
            </button>

            {!filterList || totalPages === 0 ? (
                <Row
                    style={{
                        width: 9,
                        height: 22,
                        marginLeft: 16,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Body2Bold
                        style={{
                            width: 9,
                            // height: 22,
                            color: colors.WHITE_700,
                        }}
                    >
                        {1}
                    </Body2Bold>
                </Row>
            ) : (
                renderPages()
            )}

            <button
                style={{
                    width: 24,
                    height: 24,
                    marginLeft: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    backgroundColor: colors.WHITE_300,
                }}
                onClick={onClickNextPage}
                disabled={currentPage === totalPages || totalPages === 0 || !filterList}
            >
                <img
                    style={{ width: 24, height: 24 }}
                    src={
                        !filterList
                            ? I_RIGHT_GRAY_TRIANGLE
                            : totalPages === 0
                            ? I_RIGHT_GRAY_TRIANGLE
                            : currentPage === totalPages
                            ? I_RIGHT_GRAY_TRIANGLE
                            : I_RIGHT_PRIMARY_BTN
                    }
                />
            </button>
            <button
                style={{
                    width: 24,
                    height: 24,
                    marginLeft: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    backgroundColor: colors.WHITE_300,
                }}
                onClick={onClickLastPage}
                disabled={currentPage === totalPages || totalPages === 0 || !filterList}
            >
                <img
                    style={{ width: 24, height: 24 }}
                    src={
                        !filterList
                            ? I_RIGHT_GRAY_TWO_TRIANGLE
                            : totalPages === 0
                            ? I_RIGHT_GRAY_TWO_TRIANGLE
                            : currentPage === totalPages
                            ? I_RIGHT_GRAY_TWO_TRIANGLE
                            : I_RIGHT_PRIMARY_TWO_TRIANGLE
                    }
                />
            </button>
        </Row>
    );
};

export default CommunicationPaging;
