import React, { useEffect, useState } from 'react';
import Header from '../../global/Header';
import CommunicationListHeader from './CommunicationListHeader';
import CommunicationPaging from './CommunicationPaging';
import CommunicationListBody from './CommunicationListBody';
import { calHeight } from '../../../lib/utils';
import { Col } from '../../../lib/styled';

export interface SortType {
    value: string;
    label: string;
}

let answerSortOptions: any = [
    { value: 'ENTIRE', label: '답변 전체' },
    { value: 'PENDING', label: '답변 대기' },
    { value: 'DONE', label: '답변 완료' },
];

const CommunicationList = ({ onClickSideBarButton, totalNumberCommunication, entireList, onClickDetailPage }: any) => {
    const [answerSortType, setAnswerSortType] = useState<SortType>({ value: 'ENTIRE', label: '답변 전체' });
    const [filterList, setFilterList] = useState<any>(null);
    const [isOpenAnswer, setIsOpenAnswer] = useState<boolean>(false);

    // 페이징
    const itemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterList && filterList.slice(startIndex, endIndex);
    const totalPages = filterList && Math.ceil(filterList.length / itemsPerPage);

    const toggleAnswerDropDown = () => {
        setIsOpenAnswer(!isOpenAnswer);
    };
    const handleAnswerSelect = (option: any) => {
        setAnswerSortType(option);
        setIsOpenAnswer(false);
    };

    // 다음 페이지
    const onClickNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage % 5 === 0) {
            setCurrentPage(currentPage + 1);
        }
    };

    // 이전 페이지
    const onClickPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    // 맨 처음 페이지로
    const onClickStartPage = () => {
        setCurrentPage(1);
    };
    // 맨 마지막 페이지로
    const onClickLastPage = () => {
        setCurrentPage(totalPages);
    };

    // 필터링
    useEffect(() => {
        if (entireList?.length > 0) {
            if (answerSortType.value === 'ENTIRE') {
                setFilterList(entireList);
                return;
            } else {
                const filteredList = entireList.filter((item: any) => {
                    const answerFilter =
                        answerSortType.value === 'ENTIRE' ? entireList : item.status === answerSortType.value;

                    return answerFilter;
                });

                let pendingList = filteredList.filter((item: any) => item.status === 'PENDING');
                let doneList = filteredList.filter((item: any) => item.status === 'DONE');

                pendingList.sort((a: any, b: any) => a.updatedTime - b.updatedTime);
                doneList.sort((a: any, b: any) => a.updatedTime - b.updatedTime);

                let finalFilterList = [].concat(pendingList, doneList);

                if (finalFilterList.length > 0) {
                    setFilterList(finalFilterList);
                } else {
                    setFilterList(null);
                }
            }
        }
    }, [answerSortType, entireList]);

    return (
        <>
            <Header onClick={onClickSideBarButton} headerText={'회원 소통 상자'} isFixed={false} />

            <CommunicationListHeader
                toggleAnswerDropDown={toggleAnswerDropDown}
                answerSortType={answerSortType}
                isOpenAnswer={isOpenAnswer}
                answerSortOptions={answerSortOptions}
                handleAnswerSelect={handleAnswerSelect}
                totalNumberCommunication={totalNumberCommunication}
            />
            <CommunicationPaging
                filterList={filterList}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onClickPrevPage={onClickPrevPage}
                onClickNextPage={onClickNextPage}
                onClickStartPage={onClickStartPage}
                onClickLastPage={onClickLastPage}
            />

            <CommunicationListBody currentItems={currentItems} onClickDetailPage={onClickDetailPage} />

            {filterList && (
                <Col style={{ marginBottom: calHeight(48) }}>
                    <CommunicationPaging
                        filterList={filterList}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        onClickPrevPage={onClickPrevPage}
                        onClickNextPage={onClickNextPage}
                        onClickStartPage={onClickStartPage}
                        onClickLastPage={onClickLastPage}
                    />
                </Col>
            )}
        </>
    );
};

export default CommunicationList;
