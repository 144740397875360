import React from 'react';
import { Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_BACK } from '../../../types/images';
import { Subtitle1Bold } from '../../../lib/font';

const CommunicationDetailHeader = ({ onClickPrevPage }: any) => {
    return (
        <Row
            style={{
                width: '100vw',
                height: calHeight(56),
                alignItems: 'center',
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                backgroundColor: colors.WHITE_200,
            }}
        >
            <Row
                onClick={onClickPrevPage}
                style={{ width: 24, height: 24, position: 'absolute', marginLeft: calWidth(16) }}
            >
                <img style={{ width: 24, height: 24 }} src={I_BACK} />
            </Row>

            <Row style={{ width: '100vw', height: calHeight(56), justifyContent: 'center', alignItems: 'center' }}>
                <Subtitle1Bold style={{ color: colors.BLUE_900 }}>소통 내용</Subtitle1Bold>
            </Row>
        </Row>
    );
};

export default CommunicationDetailHeader;
