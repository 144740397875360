import React from 'react';
import { Col } from '../../../lib/styled';
import { calHeight, calWidth, truncateString } from '../../../lib/utils';
import dayjs from 'dayjs';
import { colors } from '../../../lib/colors';
import { CaptionBold, CaptionLight } from '../../../lib/font';

const ScheduleBox = ({ scheduleIndex, scheduleItem }: any) => {
    return (
        <Col
            key={'cell' + scheduleIndex}
            style={{
                minWidth: calWidth(100),
                height: calHeight(80),
                borderRadius: 4,
                backgroundColor: colors.WHITE_50,
                boxShadow: '1px 1px 8px 0px #2B529D2E',
                opacity: dayjs(scheduleItem.startTime).isBefore(dayjs().format('YYYY-MM-DD H')) ? 0.36 : 1,
                border:
                    dayjs().format('YYYY-MM-DD H') === dayjs(scheduleItem.startTime).format('YYYY-MM-DD H')
                        ? '1.6px solid #7DA1E8'
                        : 0,

                justifyContent: 'center',
                marginTop: 2,
            }}
        >
            <CaptionBold
                style={{
                    marginLeft: calWidth(10),
                    height: calHeight(16),
                    color: colors.BLUE_900,
                }}
            >
                {truncateString(scheduleItem.coachName, 3)} 강사
            </CaptionBold>

            <CaptionBold
                style={{
                    marginLeft: calWidth(10),
                    height: calHeight(16),
                    color: colors.BLUE_900,
                }}
            >
                {truncateString(scheduleItem.alias, 5)} 회원
            </CaptionBold>
            <CaptionLight
                style={{
                    marginLeft: calWidth(10),
                    height: calHeight(16),
                    color: colors.WHITE_900,
                }}
            >
                {dayjs(scheduleItem.startTime).format('A HH:mm ~ ')}
                {dayjs(scheduleItem.endTime).format('HH:mm')}
            </CaptionLight>
            <CaptionLight
                style={{
                    marginLeft: calWidth(10),
                    height: calHeight(16),
                    color: colors.WHITE_900,
                }}
            >
                {scheduleItem.currentSession ? scheduleItem.currentSession : '-'}/
                {scheduleItem.totalSession ? scheduleItem.totalSession : '-'}회
            </CaptionLight>
        </Col>
    );
};

export default ScheduleBox;
