import React from 'react';
import { Col, Row } from '../../lib/styled';
import { Body1Regular, Body2Regular } from '../../lib/font';
import { colors } from '../../lib/colors';
import { calHeight, calWidth } from '../../lib/utils';
import { I_EMPTY_PROFILE } from '../../types/images';
type Props = {
    coachName: string;
    coachInviteCode: string;
    coachProfileImageUrl: string;
};
const TrainerRegisterStep3 = ({ coachName, coachInviteCode, coachProfileImageUrl }: Props) => {
    return (
        <Col>
            <Col style={{ marginLeft: calWidth(16), marginTop: calHeight(32) }}>
                <Body2Regular style={{ color: colors.WHITE_800 }}>트레이너 코드를 입력해주세요</Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>
                    {'트레이너 코드는 [라포 모바일 > 트레이너 계정 > My 코드번호]'}
                </Body2Regular>
                <Body2Regular style={{ color: colors.WHITE_800 }}>를 통해 공유받을 수 있습니다</Body2Regular>
            </Col>

            <Row style={{ width: '100vw', justifyContent: 'center' }}>
                <Col
                    style={{
                        width: calWidth(342),
                        height: calHeight(368),
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_300,
                        boxShadow: '4px 4px 8px 1px #213E7B29 inset',
                        alignItems: 'center',
                        marginTop: calHeight(36),
                    }}
                >
                    <img
                        src={I_EMPTY_PROFILE}
                        style={{ width: calHeight(80), height: calHeight(80), marginTop: calHeight(40) }}
                    />

                    <Col style={{ width: calWidth(220), marginTop: calHeight(16) }}>
                        <Body1Regular style={{ color: colors.WHITE_900, marginLeft: calWidth(12) }}>
                            강사 이름
                        </Body1Regular>
                        <Row
                            style={{
                                width: calWidth(220),
                                height: calHeight(48),
                                borderRadius: 48,
                                backgroundColor: colors.WHITE_500,
                                marginTop: calHeight(8),
                                alignItems: 'center',
                                paddingLeft: calWidth(22),
                            }}
                        >
                            <Body1Regular style={{ color: colors.WHITE_900 }}>
                                {coachName ? coachName : '-'}
                            </Body1Regular>
                        </Row>
                    </Col>
                    <Col style={{ width: calWidth(220), marginTop: calHeight(32) }}>
                        <Body1Regular style={{ color: colors.WHITE_900, marginLeft: calWidth(12) }}>
                            강사 코드
                        </Body1Regular>
                        <Row
                            style={{
                                width: calWidth(220),
                                height: calHeight(48),
                                borderRadius: 48,
                                backgroundColor: colors.WHITE_500,
                                marginTop: calHeight(8),
                                alignItems: 'center',
                                paddingLeft: calWidth(22),
                            }}
                        >
                            <Body1Regular style={{ color: colors.WHITE_900 }}>
                                {coachInviteCode ? coachInviteCode : '-'}
                            </Body1Regular>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </Col>
    );
};

export default TrainerRegisterStep3;
