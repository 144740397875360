import React, { useEffect, useState } from 'react';
import { Col, Row } from '../lib/styled';
import { colors } from '../lib/colors';
import { calHeight, calWidth, getItemWithExpiration } from '../lib/utils';
import { I_RED_ERROR } from '../types/images';
import { Body1Bold, Body1Regular, Subtitle1Bold } from '../lib/font';
import { useNavigate } from 'react-router-dom';

const ExpiredPage = ({ setIsLoading }: any) => {
    const navigate = useNavigate();

    const [planObject, setPlanObject] = useState(getItemWithExpiration('planObject'));

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <Col
            style={{
                width: '100%',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgb(94,94,94)',
            }}
        >
            <Col
                style={{
                    width: calWidth(358),
                    height: calHeight(204),
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    alignItems: 'center',
                }}
            >
                <Row style={{ width: calWidth(298), alignItems: 'center', marginTop: calHeight(24) }}>
                    <img
                        src={I_RED_ERROR}
                        style={{ width: calWidth(24), height: calWidth(24), marginRight: calWidth(8) }}
                    />
                    <Subtitle1Bold style={{ color: colors.BLUE_900 }}>구독 종료</Subtitle1Bold>
                </Row>
                <Col style={{ width: calWidth(298), marginTop: calHeight(17.36) }}>
                    <Body1Regular style={{ color: colors.WHITE_800 }}>
                        {planObject.usableStatus === 'needPlanToFree' ? '무료' : '구독'} 이용 기간이 만료되었습니다!
                    </Body1Regular>
                    <Body1Regular style={{ color: colors.WHITE_800 }}>자세한 사항은 PC로 확인해주세요!</Body1Regular>
                </Col>

                <Row
                    style={{
                        width: calWidth(148),
                        height: calHeight(48),
                        borderRadius: 48,
                        border: '1px solid #3D73DD',
                        marginTop: calHeight(13),
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => {
                        navigate('/signIn');
                    }}
                >
                    <Body1Bold style={{ color: colors.PRIMARY_BLUE }}>확인</Body1Bold>
                </Row>
            </Col>
        </Col>
    );
};

export default ExpiredPage;
