import React from 'react';
import { Col } from '../../../lib/styled';
import CustomButton from '../../Button/CustomButton';
import { calHeight } from '../../../lib/utils';

const CommunicationDetailButton = ({
    detailObject,
    onClickReplyButton,
    onClickCreateReply,
    onClickDeleteReply,
    onClickEditReplyButton,
    onClickEditReply,
    text,
    isInputMode,
    isEditMode,
}: any) => {
    return (
        <Col style={{ width: '100vw', alignItems: 'center', marginBottom: calHeight(80) }}>
            {detailObject?.status === 'PENDING' ? (
                <>
                    {isInputMode ? (
                        <CustomButton
                            onClick={onClickCreateReply}
                            disabled={text === '' ? true : false}
                            text="답변 보내기"
                            style={{ marginTop: calHeight(36) }}
                        />
                    ) : (
                        <CustomButton
                            onClick={onClickReplyButton}
                            text="답변 작성"
                            style={{ marginTop: calHeight(36) }}
                        />
                    )}
                </>
            ) : (
                <>
                    {isEditMode ? (
                        <CustomButton
                            onClick={onClickEditReply}
                            text="답변 보내기"
                            style={{ marginTop: calHeight(36) }}
                        />
                    ) : (
                        <>
                            <CustomButton
                                onClick={onClickDeleteReply}
                                text="답변 삭제"
                                style={{ marginTop: calHeight(36) }}
                            />
                            <CustomButton
                                onClick={onClickEditReplyButton}
                                text="내용 수정"
                                style={{ marginTop: calHeight(16) }}
                            />
                        </>
                    )}
                </>
            )}
        </Col>
    );
};

export default CommunicationDetailButton;
