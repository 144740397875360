import React from 'react';
import { Col, Row } from '../../lib/styled';
import { Body3Regular } from '../../lib/font';
import { calHeightToDvh, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';

type Props = {
    navigate: any;
    onClickSideBarButton: () => void;
    onClickSignOut: () => void;
};

const SideBarBottom = ({ navigate, onClickSideBarButton, onClickSignOut }: Props) => {
    return (
        <Col style={{ width: calWidth(290), marginTop: calHeightToDvh(60), marginLeft: calWidth(28) }}>
            <a
                href="http://pf.kakao.com/_ICxhcb/chat"
                target="_blank"
                style={{
                    width: calWidth(160),
                    height: calHeightToDvh(32),
                    textDecoration: 'none',
                    alignItems: 'center',
                }}
            >
                <Row>
                    <Body3Regular
                        style={{
                            width: calWidth(160),
                            height: calHeightToDvh(32),
                            paddingLeft: calWidth(12),
                            color: colors.BLUE_300,
                        }}
                    >
                        1:1 문의하기
                    </Body3Regular>
                </Row>
            </a>
            <Body3Regular
                style={{
                    width: calWidth(160),
                    height: calHeightToDvh(32),
                    paddingLeft: calWidth(12),
                    marginTop: calHeightToDvh(4),
                    color: colors.BLUE_300,
                }}
                onClick={() => {
                    onClickSideBarButton();
                    navigate('/term');
                }}
            >
                이용약관 / 개인정보 정책
            </Body3Regular>
            <Body3Regular
                style={{
                    width: calWidth(160),
                    height: calHeightToDvh(32),
                    paddingLeft: calWidth(12),
                    marginTop: calHeightToDvh(4),
                    color: colors.BLUE_300,
                }}
                onClick={onClickSignOut}
            >
                로그아웃
            </Body3Regular>
        </Col>
    );
};

export default SideBarBottom;
