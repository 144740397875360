import { useEffect, useRef, useState } from 'react';
import CommunicationDetailBody from './CommunicationDetailBody';
import CommunicationDetailHeader from './CommunicationDetailHeader';
import CommunicationDetailTop from './CommunicationDetailTop';
import CommunicationInputReply from './CommunicationInputReply';
import CommunicationDetailButton from './CommunicationDetailButton';
import { useMutation } from '@tanstack/react-query';
import {
    createCommunicationReplyAPI,
    deleteCommunicationReplyAPI,
    editCommunicationReplyAPI,
} from '../../../api/commBox';
import _ from 'lodash';

const CommunicationDetail = ({
    sendingObject,
    onClickPrevPage,
    authorization,
    runRefetch,
    createAlert,
    onClickImage,
    setPageType,
}: any) => {
    const scrollRef: any = useRef(null);
    const textareaRef: any = useRef(null);

    const [detailObject, setDetailObject] = useState<any>(null);
    const [isInputMode, setIsInputMode] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [text, setText] = useState('');
    const [editText, setEditText] = useState('');

    const handleChange = (event: any) => {
        setText(event.target.value);
        updateTextareaHeight();
    };

    const handleEditChange = (event: any) => {
        setEditText(event.target.value);
        updateTextareaHeight();
    };

    const updateTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const onClickReplyButton = () => {
        setIsInputMode(true);
    };

    const onClickEditReplyButton = () => {
        setIsEditMode(true);
    };

    const onClickCreateReply = () => {
        if (text.trim() === '') {
            console.log('공백');
        } else {
            createCommunicationReply.mutate();
        }
    };

    const onClickDeleteReply = () => {
        let alertObject = {
            title: '답글을 삭제하시겠습니까?',
            content: '회원에게 이미 전달된 답변입니다!\n답변을 삭제하시면 답변 대기상태로 돌아갑니다',
            callBack: () => deleteCommunicationReply.mutate(),
            isTwoBtn: true,
            confirmText: '삭제',
        };
        createAlert(alertObject);
    };

    const onClickEditReply = () => {
        let alertObject = {
            title: '답글을 수정하시겠습니까?',
            content: '회원에게 이미 전달된 답변입니다!\n답변을 삭제하시면 답변 대기상태로 돌아갑니다',
            callBack: () => editCommunicationReply.mutate(),
            isTwoBtn: true,
            confirmText: '수정하기',
        };
        createAlert(alertObject);
    };

    // 소통상자 답글 달기 API : (POST)
    const createCommunicationReply = useMutation({
        mutationFn: async () =>
            await createCommunicationReplyAPI(
                sendingObject?._id,
                sendingObject.memberId,
                sendingObject.adminId,
                text,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;

                const cloneObject = _.cloneDeep(detailObject);
                cloneObject.status = 'DONE';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;

                setDetailObject(cloneObject);
                runRefetch();
                setIsInputMode(false);
                setText('');
                let alertObject = {
                    title: '답글 보내기 완료',
                    content: `답변이 회원에게 성공적으로 전달되었습니다\n회원이 답변을 추가할 경우 답변대기상태로 돌아갑니다`,
                    callBack: null,
                    isTwoBtn: false,
                    confirmText: '',
                };
                createAlert(alertObject);
            }

            if (res.status === 405) {
                runRefetch();
                setPageType('list');
                let alertObject = {
                    title: '',
                    content: `이미 답변이 완료된 질문입니다.`,
                    callBack: null,
                    isTwoBtn: false,
                    confirmText: '',
                };
                createAlert(alertObject);
            }
        },
    });

    // 소통상자 답글 삭제 API : (DELETE)
    const deleteCommunicationReply = useMutation({
        mutationFn: async () => await deleteCommunicationReplyAPI(sendingObject?._id, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;

                const cloneObject = _.cloneDeep(detailObject);
                cloneObject.status = 'PENDING';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;
                setDetailObject(cloneObject);
                runRefetch();
                setIsInputMode(false);
                setText('');

                let alertObject = {
                    title: '답글 삭제 완료',
                    content: '답변이 성공적으로 삭제되었습니다',
                    callBack: null,
                    isTwoBtn: false,
                    confirmText: '',
                };
                createAlert(alertObject);
            }
        },
    });

    // 소통상자 답글 수정 API : (PATCH)
    const editCommunicationReply = useMutation({
        mutationFn: async () => await editCommunicationReplyAPI(sendingObject?._id, editText, authorization),
        onSuccess: (res) => {
            if (res.status === 200) {
                const response = res.data.communicationBox;

                const cloneObject = _.cloneDeep(detailObject);
                cloneObject.status = 'DONE';
                cloneObject.contents = response?.contents;
                cloneObject.updatedTime = response?.updatedTime;

                setDetailObject(cloneObject);

                runRefetch();
                setIsEditMode(false);
                setEditText(editText);

                let alertObject = {
                    title: '답글 수정 완료',
                    content: '답변이 성공적으로 수정되었습니다',
                    callBack: null,
                    isTwoBtn: false,
                    confirmText: '',
                };
                createAlert(alertObject);
            }
        },
    });

    useEffect(() => {
        if (sendingObject) {
            setDetailObject(sendingObject);
        }
    }, [sendingObject]);

    useEffect(() => {
        if (detailObject) {
            detailObject.contents &&
                detailObject.contents.length !== 1 &&
                setEditText(detailObject.contents[detailObject.contents.length - 1].content);
        }
    }, [detailObject]);

    return (
        <>
            <CommunicationDetailHeader onClickPrevPage={onClickPrevPage} />
            <CommunicationDetailTop detailObject={detailObject} />
            <CommunicationDetailBody
                detailObject={detailObject}
                isEditMode={isEditMode}
                textareaRef={textareaRef}
                editText={editText}
                handleEditChange={handleEditChange}
                onClickImage={onClickImage}
            />
            <CommunicationInputReply
                isInputMode={isInputMode}
                textareaRef={textareaRef}
                handleChange={handleChange}
                text={text}
            />
            <CommunicationDetailButton
                detailObject={detailObject}
                onClickReplyButton={onClickReplyButton}
                onClickCreateReply={onClickCreateReply}
                onClickDeleteReply={onClickDeleteReply}
                onClickEditReplyButton={onClickEditReplyButton}
                onClickEditReply={onClickEditReply}
                text={text}
                isInputMode={isInputMode}
                isEditMode={isEditMode}
            />
        </>
    );
};

export default CommunicationDetail;
