import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../lib/styled';
import { colors } from '../../lib/colors';
import { calHeight, calWidth } from '../../lib/utils';
import { I_WHITE_BACK } from '../../types/images';
import { Subtitle1Bold } from '../../lib/font';

const CommunicationImageViewer = ({ pictureList, setPageType }: any) => {
    const [viewPicture, setViewPicture] = useState<string>('');

    const onClickChangePicture = (url: string) => {
        setViewPicture(url);
    };

    useEffect(() => {
        if (pictureList) {
            setViewPicture(pictureList[0]);
        }
    }, [pictureList]);

    return (
        <Col style={{ width: '100vw', height: '100dvh', backgroundColor: colors.BLACK }}>
            <Row
                style={{
                    width: '100vw',
                    height: calHeight(56),
                    alignItems: 'center',
                }}
            >
                <Row
                    onClick={() => setPageType('detail')}
                    style={{ width: 24, height: 24, position: 'absolute', marginLeft: calWidth(16) }}
                >
                    <img style={{ width: 24, height: 24 }} src={I_WHITE_BACK} />
                </Row>
                <Row style={{ width: '100vw', height: calHeight(56), justifyContent: 'center', alignItems: 'center' }}>
                    <Subtitle1Bold style={{ color: colors.WHITE_50 }}>첨부 파일 보기</Subtitle1Bold>
                </Row>
            </Row>
            <Row style={{ width: '100vw', height: calHeight(390), marginTop: calHeight(100) }}>
                <img style={{ width: '100vw', height: calHeight(390), objectFit: 'scale-down' }} src={viewPicture} />
            </Row>

            <Row style={{ width: '100vw', justifyContent: 'center', marginTop: calHeight(100) }}>
                {pictureList &&
                    pictureList.map((imgUrl: string, imgIndex: number) => {
                        return (
                            <Row
                                style={{
                                    width: calWidth(56),
                                    height: calWidth(56),
                                    marginRight: calWidth(8),
                                    border: viewPicture === imgUrl ? '2px solid rgba(61, 115, 221, 1)' : '2px',
                                }}
                                onClick={() => onClickChangePicture(imgUrl)}
                            >
                                <img
                                    key={'imgDetail' + imgIndex}
                                    style={{ width: calWidth(56), height: calWidth(56) }}
                                    src={imgUrl}
                                />
                            </Row>
                        );
                    })}
            </Row>
        </Col>
    );
};

export default CommunicationImageViewer;
