import React from 'react';
import { Col, Row } from '../../../lib/styled';
import { calHeight, calWidth } from '../../../lib/utils';
import { colors } from '../../../lib/colors';
import { I_EMPTY_PROFILE } from '../../../types/images';
import { Body2Bold, CaptionRegular } from '../../../lib/font';
import dayjs from 'dayjs';

type Props = {
    isInputMode: boolean;
    textareaRef: any;
    text: string;
    handleChange: (event: any) => void;
};

const CommunicationInputReply = ({ isInputMode, textareaRef, text, handleChange }: Props) => {
    return (
        <>
            {isInputMode && (
                <Row style={{ marginTop: calHeight(16) }}>
                    <Row style={{ width: '100vw', justifyContent: 'center' }}>
                        <Col
                            style={{
                                width: calWidth(374),
                                // height: calHeight(306),
                                borderRadius: 8,
                                backgroundColor: colors.WHITE_200,
                                boxShadow: '1px 1px 8px 0px rgba(43, 82, 157, 0.18)',
                                marginTop: calHeight(16),
                            }}
                        >
                            <Row style={{ alignItems: 'center', marginLeft: calWidth(16), marginTop: calHeight(16) }}>
                                <img src={I_EMPTY_PROFILE} style={{ width: calWidth(36), height: calWidth(36) }} />
                                <Col style={{ marginLeft: calWidth(3) }}>
                                    <Body2Bold style={{ color: colors.SUCCESS }}>센터 답변</Body2Bold>
                                    <CaptionRegular style={{ color: colors.WHITE_700, marginTop: calHeight(2) }}>
                                        {dayjs().format('YYYY/MM/DD(ddd)/A HH:mm')}
                                    </CaptionRegular>
                                </Col>
                            </Row>

                            <Row
                                style={{
                                    width: calWidth(342),
                                    marginTop: calHeight(8),
                                    justifyContent: 'center',
                                    marginBottom: calHeight(16),
                                    paddingLeft: calWidth(16),
                                    paddingRight: calWidth(16),
                                }}
                            >
                                <textarea
                                    ref={textareaRef}
                                    value={text}
                                    style={{
                                        width: calWidth(342),
                                        // height: calHeight(230),
                                        borderRadius: 4,
                                        border: '1px solid rgba(165, 171, 181, 1)',
                                        boxShadow: '4px 4px 8px 1px rgba(33, 62, 123, 0.16) inset',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: calWidth(16),
                                    }}
                                    onChange={handleChange}
                                    placeholder="답변을 작성해주세요"
                                />
                            </Row>
                        </Col>
                    </Row>
                </Row>
            )}
        </>
    );
};

export default CommunicationInputReply;
