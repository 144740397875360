import secureLocalStorage from 'react-secure-storage';
import styled from 'styled-components';

export const isSafari = () => {
    const userAgent = navigator.userAgent;
    return /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
};

/**
 * @param {number} 원본 화면의 width (px 단위)
 * @param {number} 원본 화면의 height (px 단위)
 * @return {function()} 현재 화면의 크기대로 frame을 조정하는 함수
 */
export const getResizeEventListener = (standardWidth: number, standardHeight: number) => {
    return () => {
        if (isSafari()) {
            // console.log('사파리');
            const root: any = document.querySelector('#root');
            const app: any = document.querySelector('#App');

            // 원하는 해상도로 width, height 고정
            app.style.width = `${standardWidth}px`;
            app.style.height = `${standardHeight}px`;

            let width = root.clientWidth;
            let height = width * (standardHeight / standardWidth);

            // style.transform을 이용하여, 화면을 크기를 조정
            app.style.transform = `scale(${width / standardWidth}, ${height / standardHeight})`;

            if (height > root.clientHeight) {
                height = root.clientHeight;
                width = height * (standardWidth / standardHeight);

                // style.transform을 이용하여, 화면을 크기를 조정
                app.style.transform = `scale(${width / standardWidth}, ${height / standardHeight})`;
            }
        } else {
            // console.log('사파리 아님');
            const root: any = document.querySelector('#root');
            const app: any = document.querySelector('#App');

            // 원하는 해상도로 width, height 고정
            app.style.width = `${standardWidth}px`;
            app.style.height = `${standardHeight}px`;

            let width = root.clientWidth;
            let height = width * (standardHeight / standardWidth);

            // style.zoom을 이용하여, 화면을 크기를 조정
            app.style.zoom = height / standardHeight;

            if (height > root.clientHeight) {
                height = root.clientHeight;
                width = height * (standardWidth / standardHeight);

                // style.zoom을 이용하여, 화면을 크기를 조정
                app.style.zoom = width / standardWidth;
            }
        }
    };
};

// 데이터와 만료 시간을 함께 저장하는 함수
export const setItemWithExpiration = (key: any, value: any, expirationInHours: any) => {
    const now = new Date();
    const expirationTime = now.getTime() + expirationInHours * 60 * 60 * 1000; // 현재 시간에 만료 시간을 더함
    const item = { value, expirationTime };
    secureLocalStorage.setItem(key, JSON.stringify(item));
};

type Keys = 'authorization' | 'userObject' | 'centerObject' | 'planObject';
// 만료 여부를 확인하고 데이터를 가져오는 함수
export const getItemWithExpiration = (key: Keys) => {
    const storedItem: any = secureLocalStorage.getItem(key);

    try {
        if (storedItem) {
            const parsedItem = JSON.parse(storedItem);
            const now = new Date();

            if (now.getTime() < parsedItem.expirationTime) {
                return parsedItem.value;
            } else {
                // 만료된 데이터는 삭제
                secureLocalStorage.removeItem(key);
                window.location.replace('/');
            }
        }
    } catch (error: any) {
        return null;
    }

    return null; // 데이터가 없거나 만료됐을 경우 null 반환
};

// 로컬스토리지 비우기
export const clearLocalStorage = () => {
    secureLocalStorage.clear();
};

/**
 * @param  {number} 원본 화면의 width (px 단위)
 * @return {function()} 디자인 대비 width vw 비율로 수정
 */
export const calWidth = (width: number) => {
    return `${(width / 390) * 100}vw`;
};

/**
 * @param  {number} 원본 화면의 width (px 단위)
 * @return {number} 디자인 대비 width px비율 로 수정
 */
export const calWidthToPixel = (width: number) => {
    return (window.innerWidth / 390) * width;
};

/**
 * @param  {number} 원본 화면의 height (px 단위)
 * @return {function()} 디자인 대비 height vw 비율로 수정
 */
export const calHeight = (height: number) => {
    return `${(height / 844) * 100}vh`;
};

/**
 * @param  {number} 원본 화면의 height (px 단위)
 * @return {function()} 디자인 대비 height vw 비율로 수정
 */
export const calHeightToDvh = (height: number) => {
    return `${(height / 844) * 100}dvh`;
};

/**
 * @param  {number} 원본 화면의 height (px 단위)
 * @return {number} 디자인 대비 height px비율 로 수정
 */
export const calHeightToPixel = (height: number) => {
    return (window.innerHeight / 844) * height;
};

// 문자열을 받아 최대길이만큼 자르고 + '...' 붙이는함수
export const truncateString = (str: string, maxLength: number) => {
    if (str?.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    } else {
        return str;
    }
};
