import { useState } from 'react';
import { Col } from '../lib/styled';
import { colors } from '../lib/colors';
import { useQuery } from '@tanstack/react-query';
import { getItemWithExpiration, setItemWithExpiration } from '../lib/utils';
import { getCommunicationListAPI } from '../api/commBox';
import CommunicationList from '../components/Communication/CommunicationList/CommunicationList';
import CommunicationDetail from '../components/Communication/CommunicationDetail/CommunicationDetail';
import CommunicationImageViewer from '../components/Communication/CommunicationImageViewer';

const CommunicationPage = ({ onClickSideBarButton, createAlert, centerObject, setCenterObject }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [userObject, setUserObject] = useState(getItemWithExpiration('userObject'));

    const [pageType, setPageType] = useState<'list' | 'detail' | 'imageViewer'>('list');
    const [entireList, setEntireList] = useState<any>(null);
    const [totalNumberCommunication, setTotalNumberCommunication] = useState<number>(0);

    const [sendingObject, setSendingObject] = useState<any>(null);

    const [pictureList, setPictureList] = useState<any>(null);

    const onClickDetailPage = (item: any) => {
        setSendingObject(item);
        setPageType('detail');
    };

    const onClickPrevPage = () => {
        setPageType('list');
    };

    const onClickImage = (images: any) => {
        setPictureList(images);
        setPageType('imageViewer');
    };

    const runRefetch = () => {
        getCommunicationList.refetch();
    };

    // 회원 소통 상자 리스트 조회 API : (GET)
    const getCommunicationList = useQuery(
        ['getCommunicationListAPI', authorization],
        async () => await getCommunicationListAPI(authorization, userObject?.adminId),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const communicationBox = res.data.communicationBox;
                    setEntireList(communicationBox);
                    communicationBox && setTotalNumberCommunication(communicationBox.length);

                    const centerObjectData = {
                        centerAddress: centerObject?.address,
                        centerCategory: centerObject?.centerCategory,
                        centerCode: centerObject?.centerCode,
                        centerName: centerObject?.centerName,
                        centerId: centerObject?.centerId,
                        isNewCommunicationBox: res.data?.isNewCommunicationBox,
                    };

                    setCenterObject(centerObjectData);

                    setItemWithExpiration('centerObject', centerObjectData, 12);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization,
            staleTime: 0,
        }
    );

    return (
        <Col style={{ width: '100vw', minHeight: '100dvh', backgroundColor: colors.WHITE_300 }}>
            {pageType === 'list' ? (
                <CommunicationList
                    onClickSideBarButton={onClickSideBarButton}
                    entireList={entireList}
                    totalNumberCommunication={totalNumberCommunication}
                    onClickDetailPage={onClickDetailPage}
                />
            ) : pageType === 'detail' ? (
                <CommunicationDetail
                    sendingObject={sendingObject}
                    onClickPrevPage={onClickPrevPage}
                    authorization={authorization}
                    runRefetch={runRefetch}
                    createAlert={createAlert}
                    onClickImage={onClickImage}
                    setPageType={setPageType}
                />
            ) : (
                <CommunicationImageViewer pictureList={pictureList} setPageType={setPageType} />
            )}
        </Col>
    );
};

export default CommunicationPage;
