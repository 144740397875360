import React from 'react';
import { Row } from '../../lib/styled';
import { calHeight, calWidth } from '../../lib/utils';
import { colors } from '../../lib/colors';
import { Subtitle1Bold } from '../../lib/font';

const BottomButton = ({ onClickNextStep, step }: any) => {
    return (
        <Row style={{ width: '100vw', justifyContent: 'center', position: 'absolute', bottom: 90 }}>
            <Row
                style={{
                    width: calWidth(180),
                    height: calHeight(60),
                    borderRadius: 48,
                    backgroundColor: colors.WHITE_300,
                    boxShadow: '1px 1px 8px 0px #2B529D2E',

                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={onClickNextStep}
            >
                <Subtitle1Bold>{step === 3 ? '확인' : '다음'}</Subtitle1Bold>
            </Row>
        </Row>
    );
};

export default BottomButton;
